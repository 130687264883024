import React, { useRef, useState, useEffect, useCallback } from "react";
import { Stage, Layer, Image, Rect } from "react-konva";
import NavigationComponent from "../NavigationComponent/NavigationComponent";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import "../PreviewComponent/PreviewComponent.css";
import './Preview.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PDFfile from "./PDFfile";
import { PDFViewer } from "@react-pdf/renderer";
import { Document, Page } from "react-pdf";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import Loader from "../Common/Loader";

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const colors = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "orange",
  "pink",
  "cyan",
  "magenta",
];

const DataGridComponent = ({ rows, headers }) => {
  return (
    <table className="data-grid">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header.content}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell ? cell.content : ""}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const useImage = (src) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (src) {
      const img = new window.Image();
      img.src = src;
      img.onload = () => setImage(img);
      img.onerror = () => setError("Failed to load image");
    }
  }, [src]);

  return [image, error];
};

const PreviewComponent = ({ breadCrumbNames, dataAvailable }) => {
  const [rectangles, setRectangles] = useState([]);
  const [hoveredRectIndex, setHoveredRectIndex] = useState(null);
  const [imageBlobUrls, setImageBlobUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [image, error] = useImage(imageBlobUrls[currentIndex]);
  const stageRef = useRef(null);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [fileName, setFileName] = useState("");
  const [maxIndex, setMaxIndex] = useState();
  const panelRef = useRef(null);
  const stageWidth = 830;
  // const stageHeight = image ? (image.height * stageWidth) / image.width : window.innerHeight;
  const [pageSize, setPageSize] = useState({ width: 0, height: 0 });
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [scaleFactor, setScaleFactor] = useState(null);
  const [error, setError] = useState(null);
  const stageHeight = pageSize.width
    ? (pageSize.height * stageWidth) / pageSize.width
    : window.innerHeight;

  const convertToPixels = (polygon, scaleFactor) => {
    console.log("scaleFactor", scaleFactor);
    return polygon.map((coord) => coord * scaleFactor);
  };
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);

  const [pdfWidth, setPdfWidth] = useState(612);
  const [pdfHeight, setPdfHeight] = useState(792);
  const containerRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };
  const dataItemsRef = useRef([]);

  useEffect(() => {
    if (hoveredRectIndex !== null && panelRef.current) {
      const container = panelRef.current;
      const item = dataItemsRef.current[hoveredRectIndex];
  
      if (item) {
        item.scrollIntoView({
          block: "center",  
          behavior: "smooth",
        });
      }
    }
  }, [hoveredRectIndex]);



  

  const calculateBoundingBox = (polygon) => {
    // console.log("polygon", polygon);
    const xCoords = polygon.filter((_, index) => index % 2 === 0);
    const yCoords = polygon.filter((_, index) => index % 2 !== 0);


    const xMin = Math.min(...xCoords);
    const xMax = Math.max(...xCoords);
    const yMin = Math.min(...yCoords);
    const yMax = Math.max(...yCoords);

    const width = xMax - xMin;
    const height = yMax - yMin;
    return {
      x: xMin,
      y: yMin,
      width,
      height,
    };
  };

  useEffect(() => {
    console.log("Effect triggered");
  
    const updateScale = () => {
      setOpen(true);
      console.log("Container Ref:", containerRef.current);
      if (containerRef.current) {
        setOpen(false);
        const containerWidth = containerRef.current.clientWidth;
        const newScaleFactor = containerWidth / pdfWidth;
        console.log("New Scale Factor:", newScaleFactor);
        setScaleFactor(newScaleFactor);
        
        if (newScaleFactor !== scaleFactor) {
          setScaleFactor(newScaleFactor);

          console.log(pdfWidth * scaleFactor)
          console.log(pdfHeight * scaleFactor)
        }
      }
    };

    updateScale();
    window.addEventListener("resize", updateScale);

    return () => window.removeEventListener("resize", updateScale);
  }, [containerRef.current]); 

  // useEffect(() => {
  //   console.log(
  //     "fsdjflksjfklsjdlfs"
  //   )
  // },[pdfWidth])


  useEffect(() => {
    setOpen(true);
    if (dataAvailable) {
      setOpen(false);
      // const scaleFactor = 74;
      const scale = scaleFactor * 72
      // console.log("Scale Facotr", scaleFactor);
      const data = [];

      // Safely set file name and breadcrumb
      setFileName(dataAvailable.file_name || "Unknown File");
      const breadCrumbArray = (breadCrumbNames || []).map((item) => ({
        name: item.name || "",
        color: "#767676",
        underline: "none",
        href: item.href || "",
      }));
      setBreadCrumb(breadCrumbArray);
      // const sourceImages = dataAvailable.source_images || [];
      const sourceImages = dataAvailable.ai_source_images || [];
      setMaxIndex(sourceImages.length);

      const extractedData = dataAvailable.extracted_data || {};
      for (const key in extractedData) {
        if (extractedData.hasOwnProperty(key)) {
          const boundary = extractedData[key];
          // console.log("Boundary:", boundary);

          if (
            (boundary?.BoundingRegions?.length > 0) ||
            (boundary?.Boundary?.length > 0)
          ) {
            const region = boundary.BoundingRegions
              ? boundary.BoundingRegions[0]
              : boundary.Boundary[0]; // Access the first region

            if (region?.PageNumber === currentIndex + 1) {
              const polygon = Array.isArray(region.Polygon?.[0])
                ? region.Polygon.flat()
                : region.Polygon;

              if (polygon) {

                const boundingBoxPDF = calculateBoundingBox(polygon);
                const boundingBoxInPixels = convertToPixels(
                  [
                    boundingBoxPDF.x,
                    boundingBoxPDF.y,
                    boundingBoxPDF.width,
                    boundingBoxPDF.height,
                  ],
                  scale
                );

                if (boundary["Content"] || boundary.Content) {
                  data.push({
                    [key]: boundary.ValueString || boundary.Content,
                    x: boundingBoxInPixels[0],
                    y: boundingBoxInPixels[1],
                    width: boundingBoxInPixels[2],
                    height: boundingBoxInPixels[3],
                    color: colors[data.length % colors.length],
                  });
                }
              }
            }
          }
        }
      }

      // console.log(data)
      setRectangles(data);
      setImageBlobUrls(sourceImages);
    }
  }, [dataAvailable, currentIndex, scaleFactor]);


  const handleMouseEnterRect = (index) => {
    setHoveredRectIndex(index);
  };

  const handleMouseLeaveRect = () => {
    setHoveredRectIndex(null);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };
  const onDocumentLoadSuccess = ({ numPages }) => {
    // console.log("numPages", numPages)
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    setError(error.message);
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, numPages - 1));
  };
  useEffect(() => {
    setIsNextDisabled(currentIndex === numPages - 1);
    setIsPreviousDisabled(currentIndex === 0);
  }, [currentIndex, numPages]);
  
  return (
    <>
      <CustomBreadCrumbs array={breadCrumb} />
      {open ? (
        <Loader open={open} handleClose={handleClose} />
      ) : (
        <div className="general-doc-validation">
          <div className="pdf-containers">
            <NavigationComponent
              pageNumber={currentIndex + 1}
              numPages={numPages}
              setPageNumber={setCurrentIndex}
              onPrevious={handlePrevious}
              onNext={handleNext}
              isNextDisabled={isNextDisabled}
              isPreviousDisabled={isPreviousDisabled}
            />
            {imageBlobUrls.length > 0 && (
              <div ref={containerRef} className="pdf-container" style={{ width: "100%", maxWidth: "800px", margin: "auto", position: "relative", }}>
                <Document
                  file={imageBlobUrls[0]}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={onDocumentLoadError}
                >
                  <Page pageNumber={pageNumber} scale={scaleFactor}
                    onLoadSuccess={({ width, height }) => {
                    console.log("Width:", width, "Height:", height);
                      setPdfWidth(width || 800);
                      setPdfHeight(height || 1000);
                    }} />
                </Document>
                <div className="konva-container">
                  <Stage width={pdfWidth} height={pdfHeight}>
                    <Layer>
                      {rectangles.map((rect, index) => (
                        <Rect
                          key={index}
                          x={rect.x}
                          y={rect.y}
                          width={rect.width}
                          height={rect.height}
                          fill=""
                          stroke={hoveredRectIndex === index ? "red" : rect.color}
                          strokeWidth={2}
                          draggable={false}
                          onMouseEnter={() => handleMouseEnterRect(index)}
                          onMouseLeave={handleMouseLeaveRect}
                          onDragEnd={(e) => {
                            const newRectangles = [...rectangles];
                            newRectangles[index] = {
                              ...rect,
                              x: e.target.x(),
                              y: e.target.y(),
                            };
                            setRectangles(newRectangles);
                          }}
                          onTransformEnd={(e) => {
                            const node = e.target;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();

                            node.scaleX(1);
                            node.scaleY(1);
                            const newRectangles = [...rectangles];
                            newRectangles[index] = {
                              ...rect,
                              x: node.x(),
                              y: node.y(),
                              width: Math.max(5, node.width() * scaleX),
                              height: Math.max(5, node.height() * scaleY),
                            };
                            setRectangles(newRectangles);
                          }}
                        />
                      ))}
                    </Layer>
                  </Stage>
                </div>
              </div>
            )}
          </div>

          <div className="panel" ref={panelRef}>
            <div className="container">
              {rectangles.map((item, index) => {
                const isTable = item.type === "Table";

                const [key, value] = Object.entries(item).find(
                  ([k]) =>
                    k !== "x" &&
                    k !== "y" &&
                    k !== "width" &&
                    k !== "height" &&
                    k !== "color"
                );
                return (
                  <div
                    key={index}
                    className={`data-item ${hoveredRectIndex === index ? "highlight" : ""
                      }`}
                    ref={(el) => (dataItemsRef.current[index] = el)}
                  >
                    {isTable ? (
                      <div className="table-container">
                        <DataGridComponent
                          rows={item.rows}
                          headers={item.headers}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="label">{key}</div>
                        <div className="value">
                          {key === "Blob URL" ? (
                            <a
                              href={value}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Document
                            </a>
                          ) : (
                            value
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>
          </div>

        </div>
      )}
    </>
  );
};


export default PreviewComponent;
