import React, { useEffect, useState, useMemo } from "react";
import "./MyUsage.css";
import GriDataComponent from "../GridDataComponent/CommonTable";
import { Grid } from "@mui/material";
import apiService from "../../service";
import moment from "moment";
import Loader from "../Common/Loader";

function GridUsageDetails() {
    const [userData, setUserData] = useState(null);
    const [open, setOpen] = useState(false);

    const getStatusStyle = (status) => {
        switch (status) {
            case "Completed": return "status-active";
            case "Failed": return "status-inactive";
            case "In progress": return "status-pending";
            default: return "status-common";
        }
    };

    const columns = useMemo(() => {
        const baseColumns = [
            { field: "file_name", headerName: "File Name", flex: 3, renderCell: (params) => <span className="organization-name">{params.value}</span> },
            { field: "model", headerName: "Model Name", flex: 2, renderCell: (params) => <span className="organization-name">{params.value}</span> },
            { field: "pages_scanned", headerName: "Pages Scanned", flex: 2, renderCell: (params) => <span className="organization-name">{params.value}</span> },
            { field: "status", headerName: "Status", flex: 3, renderCell: (params) => <span className={getStatusStyle(params.value)}>{params.value}</span> },
            { field: "uploaded_by", headerName: "Uploaded By", flex: 2, renderCell: (params) => <span className="common-texts">{params.value}</span> },
            { field: "uploadedon", headerName: "Uploaded On", flex: 2, renderCell: (params) => <span className="common-texts">{params.value}</span> },
        ];

        if (userData?.Consumer_Type !== "postpaid") {
            baseColumns.splice(2, 0, { field: "credit_used", headerName: "Credit Used", flex: 2, renderCell: (params) => <span className="organization-name">{params.value}</span> });
        }
        return baseColumns;
    }, [userData]);

    useEffect(() => {
        const getUsageDetails = async () => {
            try {
                setOpen(true);
                const response = await apiService.userUsageDetails();
                setUserData(response);
            } catch (error) {
                console.error("Error in fetching usage details", error);
            } finally {
                setOpen(false);
            }
        };
        getUsageDetails();
    }, []);

    const formattedRows = useMemo(() => {
        return userData?.documents?.map((doc, index) => ({
            id: doc.id || index,
            file_name: doc.file_name || "",
            model: doc.model || "",
            uploaded_by: doc.uploaded_by || "",
            uploadedon: moment(doc.created_at).format("DD MMM YYYY h:mm A"),
            pages_scanned: doc.pages_scanned || "",
            status: doc.status,
            credit_used: userData?.Consumer_Type !== "postpaid" ? doc.credits_used || "" : undefined,
            created_at: doc.created_at,
        })).sort((a, b) => new Date(b.created_at) - new Date(a.created_at)) || [];
    }, [userData]);

    return (
        <Grid container spacing={2}>
            {open && <Loader open={open} handleClose={() => setOpen(false)} />}
            <Grid item xs={12}>
                <GriDataComponent
                    checkboxSelection
                    rows={formattedRows}
                    columns={columns}
                    rowCount={formattedRows.length}
                    paginationMode="client"
                    className="usage-table"
                    pageSizeOptions={[25, 50, 100]}
                    fileName="My Usage"
                />
            </Grid>
        </Grid>
    );
}

export default GridUsageDetails;