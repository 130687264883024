import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import ShowToast from "../../Common/ReactToastifyNotification/Toastify";


export const exportDataToExcel = (rows, fileName) => {
  if (!rows || rows.length === 0) {
    ShowToast.warning(`No data available for export: ${fileName}`);
    return;
  }

  const exportData = rows.map((row) => {
    const createdAt = moment(row.uploadedon, "DD MMM YYYY h:mm A");

    return {
      "File Name": row.fileName || "",
      "Uploaded On": createdAt.isValid() ? createdAt.format("DD MMM YYYY h:mm A") : "",
      "Fields Detected": row.extractedFields.join(", "), 
      "Uploadedby": row.uploadedby || "",
      "Status": row.status || "",
    };
  });

  // Convert to Excel format and download
  const worksheet = XLSX.utils.json_to_sheet(exportData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const blobData = new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  saveAs(blobData, fileName + ".xlsx");
};