import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const Success = () => {
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const razorpayPaymentId = queryParams.get('razorpay_payment_id');
    const finalId = id || razorpayPaymentId;
    console.log(id);
    const navigate = useNavigate();  
    
    useEffect(() => {
        const redirectLocation = localStorage.getItem("redirectLocation") || '/subscription';
        if (finalId && redirectLocation) {
            localStorage.removeItem("redirectLocation");
            navigate(redirectLocation);
        }
    }, [id, navigate, finalId]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography className="subscription-text">Payment Successful</Typography>
            </Grid>
        </Grid>
    );
};

export default Success;
