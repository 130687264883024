import React, { createContext, useContext, useState, useCallback } from 'react';
import apiService from './service';

// Create the context
const CreditPointsContext = createContext(undefined);

export const CreditPointsProvider = ({ children }) => {
    const [creditPoints, setCreditPoints] = useState(0);

    const fetchCreditPoints = useCallback(async (userId) => {
        console.log("Fetching credit points for user:", userId);
        if (!userId) return;

        try {
            const response = await apiService.userCreditPoints(userId);
            if (response.status === 200) {
                const balance = response.data?.balance || 0;
                localStorage.setItem("creditPoints", balance.toString());
                setCreditPoints(balance);
            }
        } catch (err) {
            console.error("Failed to fetch credit points:", err);
        }
    }, []);

    return (
        <CreditPointsContext.Provider value={{ creditPoints, setCreditPoints, fetchCreditPoints }}>
            {children}
        </CreditPointsContext.Provider>
    );
};

export const useCreditPoints = () => {
    const context = useContext(CreditPointsContext);
    if (context === undefined) {
        throw new Error('useCreditPoints must be used within a CreditPointsProvider');
    }
    return context;
};
