// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiGrid-root.subscription-container{
  display: block;
  @media screen and (min-width: 768px){
    display: flex;
    align-items: center;
  }
}
.MuiGrid-root.currencySelector{
    display: flex;
    justify-content: end;
    margin-top: 20px;
    flex-direction: column;

}
.MuiTypography-root.payment-text{
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
    margin-bottom: 2rem;
}
.MuiTypography-root.currency-text{
    font-size: 14px;
    font-family: 'Inter';
    font-weight: 500;
}
.MuiTypography-root.flag-text{
    font-size: 12px;
    font-family: 'Inter';
    font-weight: 400;
}
.MuiBox-root.flag-container{
    display: flex;
    align-items: center;
    gap: 8px
}
.flag-icon{
    width: 20px;
    height: 14px;
    border-radius: 2px;
}
.MuiTypography-root.subscription-text{
    font-size: 24px;
    font-family: 'Inter';
    font-weight: 600;
    margin-bottom: 2rem;
}
.subscriptiontext{
    cursor: pointer;
    color: #000;
    font-weight: 600;
    font-family: Public Sans;
    font-size: 18px;
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/Subscription/Subscription.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd;IACE,aAAa;IACb,mBAAmB;EACrB;AACF;AACA;IACI,aAAa;IACb,oBAAoB;IACpB,gBAAgB;IAChB,sBAAsB;;AAE1B;AACA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB;AACJ;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,eAAe;IACf,oBAAoB;IACpB,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,wBAAwB;IACxB,eAAe;IACf,0BAA0B;AAC9B","sourcesContent":[".MuiGrid-root.subscription-container{\n  display: block;\n  @media screen and (min-width: 768px){\n    display: flex;\n    align-items: center;\n  }\n}\n.MuiGrid-root.currencySelector{\n    display: flex;\n    justify-content: end;\n    margin-top: 20px;\n    flex-direction: column;\n\n}\n.MuiTypography-root.payment-text{\n    font-size: 12px;\n    font-family: 'Inter';\n    font-weight: 400;\n    margin-bottom: 2rem;\n}\n.MuiTypography-root.currency-text{\n    font-size: 14px;\n    font-family: 'Inter';\n    font-weight: 500;\n}\n.MuiTypography-root.flag-text{\n    font-size: 12px;\n    font-family: 'Inter';\n    font-weight: 400;\n}\n.MuiBox-root.flag-container{\n    display: flex;\n    align-items: center;\n    gap: 8px\n}\n.flag-icon{\n    width: 20px;\n    height: 14px;\n    border-radius: 2px;\n}\n.MuiTypography-root.subscription-text{\n    font-size: 24px;\n    font-family: 'Inter';\n    font-weight: 600;\n    margin-bottom: 2rem;\n}\n.subscriptiontext{\n    cursor: pointer;\n    color: #000;\n    font-weight: 600;\n    font-family: Public Sans;\n    font-size: 18px;\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
