// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-style{
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 768px){
        width: calc(100% - 260px);
    }
}`, "",{"version":3,"sources":["webpack://./src/components/MainComponent/MainComponent.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".main-style{\n    flex-shrink: 0;\n    width: 100%;\n    height: 100%;\n    @media screen and (min-width: 768px){\n        width: calc(100% - 260px);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
