// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-grid {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-grid th,
  .data-grid td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  
  .data-item .label {
    font-weight: bold;
  }
  
  .data-item.highlight {
    background-color: #f0f0f0;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Preview/Preview.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,yBAAyB;EAC3B;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".data-grid {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  .data-grid th,\n  .data-grid td {\n    border: 1px solid #ccc;\n    padding: 8px;\n    text-align: left;\n  }\n  \n  .data-item .label {\n    font-weight: bold;\n  }\n  \n  .data-item.highlight {\n    background-color: #f0f0f0;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
