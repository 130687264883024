import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import apiService from "../../service";
import { toast } from "react-toastify";
import ToastMessage from "../Common/ReactToastifyNotification/Toastify";
import './VerificationId.css';

const Verification = () => {
    const { verificationId } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyUser = async () => {
            if (!verificationId) return;
            const token = localStorage.getItem("token");

            if(!token) {
                navigate("/login");
                return;
            }
            try {
                const verifyResponse = await apiService.verificationValid(verificationId);

                if (verifyResponse?.status === 'true') {
                    ToastMessage.success(verifyResponse.message);

                    const accessList = verifyResponse?.data?.access_list || [];

                    if (!Array.isArray(accessList)) {
                        console.error("Invalid accessList format", accessList);
                        navigate("/login");
                        return;
                    }
                    localStorage.setItem("access", JSON.stringify(accessList));
                    if (accessList.includes("IDCriminalDocument")) {
                        navigate("/my-ai/criminal-document");
                    } else {
                        navigate("/doc-ai/invoice");
                    }
                }
            } catch (err) {
                console.error("Verification failed, Please Login", err);
                ToastMessage.error("Verification failed, Please Login");
                navigate("/login");
            }
        };

        verifyUser();
    }, [verificationId]);

    return (
        <>
            <Typography variant="h3" className="verify-test">Verification Completed successfully.</Typography>
        </>
    );
};

export default Verification;
