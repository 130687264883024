import React, { useState, useEffect } from "react";

const Zapier = () => {
    const [zapierAuth, setZapierAuth] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem("zapier_token");
        if (token) {
            setZapierAuth(token);
        }
    }, []);

    const handleLogin = () => {
        const zapierLoginUrl = "https://zapier.com/app/login"; // Update if using OAuth
        window.open(zapierLoginUrl);
    };

    return (
        <div>
               <iframe
                    src="https://zapier.com/app/login"
                    width="100%"
                    height="750px650px"
                    title="Zapier Dashboard"
                ></iframe>
            {/* <h1>Zapier Integration</h1>
            {!zapierAuth ? (
                <button onClick={handleLogin}>Login to Zapier</button>
            ) : (
                <iframe
                    src="https://zapier.com/app/dashboard"
                    width="100%"
                    height="600px"
                    title="Zapier Dashboard"
                ></iframe>
            )} */}
        </div>
    );
};

export default Zapier;