// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container-modal{
    display: flex;
    justify-content: end;
}
.header-cell{
    font-size: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Aadhar/aadharCard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,oBAAoB;AACxB;AACA;IACI,eAAe;AACnB","sourcesContent":[".button-container-modal{\n    display: flex;\n    justify-content: end;\n}\n.header-cell{\n    font-size: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
