import React, { useEffect, useState, useRef } from "react";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";
import "../../CriminalCertificateValidation.css";
import { Box, Tooltip } from "@mui/material";
import Download from "../../assets/Download.svg";
import Plus from "../../assets/upload-plus.png";
import Analyse from "../../Analyse";
import apiService from "../../service";
import { useNavigate } from "react-router-dom";
import GridDataComponent from "./CommonTable";
import View from "../../assets/view.svg";
import Delete from "../../assets/delete.svg";
import ButtonComponent from "../ButtonComponent/Button";
import Chip from "@mui/material/Chip";
import moment from "moment";
import Loader from "../Common/Loader";
import { exportDataToExcel } from "../Common/ExportDataToExcel/ExportDataToExcel";
import useWebSocket from "../../useWebSoket/UsewebSoket";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Message } from "@mui/icons-material"
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ShowToast from "../Common/ReactToastifyNotification/Toastify"
import ConfirmDeleteDialog from "../Common/CommonDelete/CommonDelete";
import { useCreditPoints } from "../../creditPoints";

const DocumentDetails = ({ documentType, title, keyAccess, exportDataToExcelName, analysefrom, deleteText, modelName, webSocketName }) => {
    const access = localStorage.getItem("access") || "";
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const [sortModel, setSortModel] = useState([
        { field: "uploadedon", sort: "desc" },
    ]);
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [open, setOpen] = useState(false);
    const {creditPoints, setCreditPoints} = useCreditPoints();

    const breadCrumbArray = [
        {
            name: title,
            color: "#767676",
            underline: "none",
        },
    ];

    const [dataAvailable, setDataAvailable] = useState([]);
    const [showDataGrid, setShowDataGrid] = useState(false);
    const tableHeight = `calc(100vh - 200px)`;
    const [isAnalyseOpen, setIsAnalyseOpen] = useState(false);
    const [showUploadDropdown, setShowUploadDropdown] = useState(false);
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 25,
    });
    const [filteredRows, setFilteredRows] = useState([]);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [currentPageData, setCurrentPageData] = useState([]);
    const [Isopen, setIsOpen] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [deleterowId,setDeleteRowId] = useState(null)
    const openDeleteDialog = (id) => {
      setSelectedId(id);
      setIsOpen(true);   
      setDeleteRowId(id)
    };
    useEffect(() => {
        if (access && access.includes(keyAccess)) {
            setShow(true);
        } else {
            setShow(false)
        }
    }, [access]);

    const toggleUploadDropdown = () => {
        setShowUploadDropdown(!showUploadDropdown);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleFileClick = (id, file_name) => {
        navigate(`/${modelName}/${analysefrom}/preview/${id}`);
    };
    const handleDeleteFile = (type, id) => async () => {
        try {
            const response = await apiService.deleteDocument(type, id);
            if (response.status) {
                fetchDataAsync(true, 'remove');
            }
        } catch (error) {
            ShowToast.error("Failed to delete document");
        }
    };

    const removeDuplicates = (data) => {
        return data.filter((value, index, self) => {
            return index === self.findIndex((t) => t.id === value.id);
        });
    };
    //page limit call here after 100 data
    const fetchDataAsync = async (fetchApi, remove) => {
        const totalDataFetched = paginationModel.page * paginationModel.pageSize;
        const currentRangeEnd = Math.ceil(totalDataFetched / 100) * 100;
        const requiredPage = Math.ceil(currentRangeEnd / 100);
        if (hasLoaded && currentRangeEnd === 0 && !fetchApi) {
            return;
        }

        if (totalDataFetched % 100 === 0 || fetchApi) {
            setHasLoaded(true);
            const newPageData = await fetchData(requiredPage);
            if (Array.isArray(newPageData) && newPageData.length > 0) {
                const updatedData = removeDuplicates([
                    ...dataAvailable,
                    ...newPageData,
                ]);
                setDataAvailable(remove ? newPageData : updatedData);
                setRows(remove ? newPageData : updatedData); // Update rows state
            } else {
                if (remove) {
                    setDataAvailable([]);
                    setRows([]); // Clear rows state
                }
                console.error("No new data available from API!");
            }
        }
        const startIndex = (paginationModel.page - 1) * paginationModel.pageSize;
        const endIndex = Math.min(
            startIndex + paginationModel.pageSize,
            dataAvailable.length
        );
        setCurrentPageData(dataAvailable.slice(startIndex, endIndex));
    };

    useEffect(() => {
        fetchDataAsync();
    }, [paginationModel, hasLoaded]);

    const handlePaginationModelChange = (newModel) => {
        const { page, pageSize } = newModel;
        const startIndex = page * pageSize;
        const endIndex = startIndex + pageSize;
        setFilteredRows(rows.slice(startIndex, endIndex));
        setPaginationModel(newModel);
    };
    //Aadhar limit api call here
    const fetchData = async (currentPage) => {
        setOpen(true);
        try {
            const response = await apiService.getCommonLimits(
                currentPage + 1,
                documentType,
                100
            );

            if (response && Array.isArray(response.documents)) {
                setOpen(false);
                setRowCount(response.total_docs || 0);
                setShowDataGrid(true);
                return response.documents;
            } else {
                console.error("Invalid response format:", response);
                setOpen(false);
                setShowDataGrid(false);
                return [];
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setOpen(false);
            setShowDataGrid(false);
            return [];
        }
    };

    const updateRows = () => {
        const updatedRows = dataAvailable?.map((row) => {
            const createdAt = moment(row.updated_at).local();
            const latestMessage = messages[messages.length - 1];
            const newCreditBalance = latestMessage && latestMessage.credits_balance ? latestMessage.credits_balance : creditPoints;
            setCreditPoints(newCreditBalance);
            const uploadedby = row.uploaded_by ? row.uploaded_by : "";

            const existingRow = rows.find((existing) => existing.id === row.id);
            let status;
            if (!existingRow) {
                status = latestMessage ? latestMessage.status : row.status;
            } else {
                status = latestMessage && latestMessage.id === row.id
                    ? latestMessage.status
                    : existingRow.status;
            }
            const extractedFields = row.extracted_data
            ? Object.values(row.extracted_data).map((field) => field.Content || "")
            : [];

            return {
                id: row.id ? row.id : "",
                fileName: row.file_name,
                uploadedon: createdAt.isValid()
                    ? createdAt.format("DD MMM YYYY h:mm A")
                    : "",
                extractedFields,
                status,
                uploadedby,
            };
        });
        if (updatedRows.length > 0) {
            setRows(updatedRows);
        }
    };
    useEffect(() => {
        updateRows()
    }, [dataAvailable, Message]);

    const handleSortModelChange = async (newSortModel) => {
        setSortModel(newSortModel);
        const sortField = newSortModel[0]?.field || "created_at";
        const sortOrder = newSortModel[0]?.sort || "desc";

        try {
            const response = await apiService.getCommonSortAPI(
                sortField,
                sortOrder,
                documentType,
                1,
                100
            );
            const data = response;
            if (response && Array.isArray(data)) {
                const sortedRows = data.map((row) => {
                    const createdAt = moment(row.updated_at).local();
                    const latestMessage = messages[messages.length - 1];
                    const uploadedby = row.uploaded_by ? row.uploaded_by : "";

                    const existingRow = rows.find((existing) => existing.id === row.id);

                    let status;
                    if (!existingRow) {
                        status = latestMessage ? latestMessage.status : row.status;
                    } else {
                        status = latestMessage && latestMessage.id === row.id
                            ? latestMessage.status
                            : existingRow.status;
                    }

                    return {
                        id: row.id ? row?.id : "",
                        fileName: row.file_name,
                        uploadedon: createdAt.isValid()
                            ? createdAt.format("DD MMM YYYY h:mm A")
                            : "",
                        extractedFields: row.extracted_data
                            ? renderExtractedFields(row)
                            : "",
                        status,
                        uploadedby,
                    };
                });

                setRows(sortedRows);
            } else {
                console.error("Error fetching sorted data:", data);
            }
        } catch (error) {
            console.error("API call failed:", error);
        }
    };

    const token = localStorage.getItem('token')
    const messages = useWebSocket(webSocketName, token)

    console.log("messages", messages, dataAvailable)
    useEffect(() => {
        if (messages && messages.length > 0) {
            setDataAvailable((prevData) => apiService.updateDataAvailable(prevData, messages));
        }
    }, [messages]);

    const renderExtractedFields = (row) => {
        const extractedData =
            dataAvailable.find((data) => data.id === row.id)?.extracted_data || {};
        const extractedValuesArray = Object.values(extractedData)
            .filter((field) => field?.Content)
            .map((field) => field.Content);
        const maxChips = 2;
        const displayedFields = extractedValuesArray.slice(0, maxChips);
        const hiddenFields = extractedValuesArray.slice(maxChips);
        const hiddenFieldsCount =
            extractedValuesArray.length - displayedFields.length;
        const chipStyles = [
            { backgroundColor: "#F5F6F7", color: "#091E42" },
            { backgroundColor: "#F7E8EF", color: "#6A1039" },
            { backgroundColor: "#E8EEF7", color: "#6C86AF" },
        ];

        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                {displayedFields.map((field, index) => (
                    <Chip
                        key={field}
                        label={field}
                        variant="outlined"
                        size="small"
                        style={{
                            margin: "2px",
                            ...chipStyles[index % chipStyles.length],
                            border: 0,
                            width: "130px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            padding: '16px 10px',
                            fontFamily: 'Inter',
                            fontWeight: 500,
                            fontSize: '12px',
                        }}
                    />
                ))}
                {hiddenFieldsCount > 0 && (
                    <Tooltip
                        title={
                            <Box
                                sx={{
                                    display: "flex",
                                    background: "white",
                                    flexWrap: "wrap",
                                    gap: "4px",
                                    p: 1, // Padding to create some space around the chips
                                }}
                            >
                                {hiddenFields.map((field, index) => (
                                    <Chip
                                        key={field}
                                        label={field}
                                        variant="outlined"
                                        size="small"
                                        style={{ margin: "2px", border: 0 }}
                                    />
                                ))}
                            </Box>
                        }
                        arrow
                        placement="top"
                    >
                        <Chip
                            key="more"
                            label={`+${hiddenFieldsCount}`}
                            variant="outlined"
                            size="small"
                            style={{
                                margin: "2px",
                                backgroundColor: "#F2F4F7",
                                color: "#344054",
                                height: "30px",
                            }}
                        />
                    </Tooltip>
                )}
        </div>
        );
    };

    const addTooltipToCell = (params) => (
        <Tooltip
            title={params.value ? params.value.toString() : ""}
            arrow
            placement="left"
        >
            <span
                style={{ cursor: "pointer" }}
                className="file-name"
                onClick={() => handleFileClick(params.row.id, params.row.file_name)}
            >
                {params.value}
            </span>
        </Tooltip>
    );

    const renderActions = (params) => {
        return (
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <VisibilityOutlinedIcon
                    style={{
                        cursor: params?.row?.status === "Completed" || params?.row?.status === "Success" ? "pointer" : "not-allowed",
                        opacity: 0.5,
                    }}
                    onClick={() => {
                        if (params?.row?.status === "Completed" || params.row.status === "Success") {
                            handleFileClick(params.row.id, params.row.status);
                        }
                    }}
                />
                <img
                    style={{ cursor: "pointer" }}
                    src={Delete}
                    alt="Delete"
                    height={20}
                    onClick={() => openDeleteDialog(params.row.id)}/>
            </div>
        );
    };

    const columns = [
        {
            field: "fileName",
            headerName: "File Name",
            flex: 2,
            headerClassName: "header-cell",
            headerAlign: "left",
            renderCell: (params) => addTooltipToCell(params),
        },
        {
            field: "uploadedon",
            headerName: "Uploaded On",
            flex: 2,
            headerClassName: "header-cell",
            headerAlign: "left",
            renderCell: (params) => {
                const date = moment(params.value);
                return (
                    <span className="uploaded-on">
                        {date.isValid() ? date.format('DD MMM YYYY h:mm A') : ""}
                    </span>
                );
            },
        },
        {
            field: "extracted",
            headerName: "Fields Detected",
            flex: 4,
            headerClassName: "app-theme-header",
            renderCell: (params) => renderExtractedFields(params.row),

        },
        {
            field: 'uploadedby',
            headerName: 'Uploaded By',
            flex: 2,
            headerClassName: 'header-cell',
            headerAlign: 'left',
            renderCell: (params) => {
                const uploadedBy = params.row.uploadedby;
                return (
                    <span className="uploaded-on">
                        {uploadedBy}
                    </span>
                );
            },

        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 2,
            headerClassName: 'header-cell',
            headerAlign: 'left',
            renderCell: (params) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                            alignItems: 'center',
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        {params.row.status !== undefined &&
                            <Chip
                                label={params.value}
                                variant='outlined'
                                size='small'
                                style={{
                                    margin: '2px',
                                    width: '130px',
                                    padding: '16px 10px',
                                    fontWeight: 500,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    fontFamily: 'Inter',
                                    fontSize: '12px',
                                    border: 'none',
                                    backgroundColor: params.value === 'Completed' || params.value === 'Success'
                                        ? '#CCFFD2'
                                        : params.value === 'In progress'
                                            ? '#FFEBCC'
                                            : params.value === 'New'
                                                ? '#CCF6FF'
                                                : params.value === 'Failed'
                                                    ? '#FFCCD0'
                                                    : '#C5CAE9',
                                    color:
                                        params.value === "Completed" || params.value === "Success"
                                            ? "#19AD19"
                                            : params.value === "In progress"
                                                ? "#EC8517"
                                                : params.value === "New"
                                                    ? "#1963AD"
                                                    : params.value === "Failed"
                                                        ? "#E0282B"
                                                        : "#3F51B5",
                                }}
                            />
                        }
                    </div>
                );
            },
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            headerClassName: "header-cell",
            headerAlign: "left",
            renderCell: renderActions,
        },
    ];

    const openModalFunction = () => {
        setIsAnalyseOpen(true);
    };

    return (
        <>
            {open ? (
                <Loader open={open} handleClose={handleClose} />
            ) : (
                <>
                    {show ? (
                        <div className="page-container">
                            <div className="ocr-form-container App">
                                {dataAvailable && showDataGrid && (
                                    <div className="view-all-container">
                                        <div className="button-container-modal">
                                            <CustomBreadCrumbs array={breadCrumbArray} />
                                            <div className="button-container-end">
                                                <ButtonComponent
                                                    onClick={() => exportDataToExcel(rows, exportDataToExcelName)}
                                                    className="excel-download"
                                                    icon={Download}
                                                    iconAlt="Download"
                                                    iconHeight={15}
                                                    text="Export"
                                                />
                                                <ButtonComponent
                                                    onClick={openModalFunction}
                                                    buttonRef={buttonRef}
                                                    className="analyse-upload"
                                                    icon={Plus}
                                                    iconAlt="Upload"
                                                    iconHeight={15}
                                                    text="Upload"
                                                />
                                            </div>
                                        </div>

                                        {showUploadDropdown && (
                                            <div className="upload-dropdown" ref={dropdownRef}>
                                                <h3>Drag and Drop to upload files</h3>
                                                <p>PDF files not more than 5 MB</p>
                                                <div className="upload-actions">
                                                    <button className="select-file-button">
                                                        Select File
                                                    </button>
                                                    <button
                                                        className="cancel-button"
                                                        onClick={toggleUploadDropdown}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button className="analyze-button">Analyze</button>
                                                </div>
                                            </div>
                                        )}
                                        <Box
                                            sx={{
                                                height: tableHeight,
                                                width: "100%",
                                                "& .app-theme-header": {
                                                    backgroundColor: "#EAECF0",
                                                    color: "black",
                                                },
                                            }}
                                        >
                                            <GridDataComponent
                                                rows={rows}
                                                columns={columns}
                                                rowCount={rowCount}
                                                paginationMode='client'
                                                className="datagrid-class"
                                                checkboxSelection={false}
                                                onSortModelChange={handleSortModelChange}
                                                localeText={{
                                                    MuiTablePagination: {
                                                        labelRowsPerPage: "Rows per page",
                                                    },
                                                }}
                                                pageSizeOptions={[25, 50, 100]}
                                                paginationModel={paginationModel}
                                                onPaginationModelChange={handlePaginationModelChange}
                                                openModalFunction={openModalFunction}
                                                fileName={title}
                                            />
                                        </Box>
                                    </div>
                                )}
                            </div>
                            <Analyse
                                isOpen={isAnalyseOpen}
                                onClose={() => setIsAnalyseOpen(false)}
                                from={analysefrom}
                                fetchDataAsync={fetchDataAsync}
                            />
                        </div>
                    ) : (
                        <GeneralDocValidation />
                    )}
                </>
            )}           
        {Isopen &&
        <ConfirmDeleteDialog
        Isopen={Isopen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleDeleteFile(deleteText,deleterowId)}/>}
        </>
    );
};

export default DocumentDetails;
