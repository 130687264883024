import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const PageTitle = () => {
  const location = useLocation();

  useEffect(() => {
    const pathToTitle = {
        "/login": "HaawkAI / login",
        "/signup": "HaawkAI / signup",
        "/verification": "HaawkAI / verification",
        "/forgotpassword": "HaawkAI / forgot-password",
        "/terms-of-service": "HaawkAI / terms-of-service",
        "/privacy-policy": "HaawkAI / privacy-policy",
        "/reset-password": "HaawkAI / reset-password",
        "/profile": "HaawkAI / profile",
        "/doc-ai/invoice": "HaawkAI / invoice",
        "/doc-ai/aadhar-card": "HaawkAI / aadhar-card",
        "/doc-ai/pan": "HaawkAI / pan",
        "/doc-ai/gst-certificate": "HaawkAI / gst-certificate",
        "/doc-ai/business-card": "HaawkAI / business-card",
        "/doc-ai/credit-card": "HaawkAI / credit-card",
        "/doc-ai/id-document": "HaawkAI / ID-document",
        "/doc-ai/bank-cheque": "HaawkAI / bank-cheque",
        "/doc-ai/eway-bill": "HaawkAI / eway-bill",
        "/doc-ai/receipt": "HaawkAI / receipt",
        "/doc-ai/bank-passbook": "HaawkAI / bank-passbook",
        "/doc-ai/general-document": "HaawkAI / general-document",
        "/my-ai/criminal-document": "HaawkAI / criminal-document",
        "/vision-ai/face-detection": "HaawkAI / face detection",
        "/vision-ai/liveness-detection": "HaawkAI / liveness detection",
        "/vision-ai/product-deduction": "HaawkAI / product deduction",
        "/vision-ai/image-search": "HaawkAI / image search",
        "/vision-ai/video-search": "HaawkAI / video search",
        "/vision-ai/video-summary": "HaawkAI / video summary",
        "/subscription": "HaawkAI / subscription",
        "/my-usage": "HaawkAI / my-usage",
        "/my-ai/criminal-document/preview/:id": "HaawkAI / criminal-document",
        "/doc-ai/aadhar-card/preview/:id": "HaawkAI / aadhar-card",
        "/doc-ai/invoice/preview/:id": "HaawkAI / invoice",
        "/doc-ai/pan/preview/:id": "HaawkAI / PAN",
        "/doc-ai/gst-certificate/preview/:id": "HaawkAI / gst-certificate",
        "/doc-ai/business-card/preview/:id": "HaawkAI / business-card",
        "/doc-ai/id-document/preview/:id": "HaawkAI / ID-document",
        "/doc-ai/bank-cheque/preview/:id": "HaawkAI / bank-cheque",
        "/doc-ai/eway-bill/preview/:id": "HaawkAI / eway-bill",
        "/doc-ai/receipt/preview/:id": "HaawkAI / receipt",
        "/doc-ai/bank-passbook/preview/:id": "HaawkAI / bank-passbook",
        "/doc-ai/general-document/preview/:id": "HaawkAI / general-document",
    };


    let pageTitle = "HaawkAI"; 

    // Check for exact path match first
    if (pathToTitle[location.pathname]) {
      pageTitle = pathToTitle[location.pathname];
    } else {
      // Handle dynamic paths with parameters
      for (const path in pathToTitle) {
        if (path.includes(":id")) {
          const regex = new RegExp("^" + path.replace(":id", "(.+)") + "$");
          const match = location.pathname.match(regex);
          if (match) {
            pageTitle = pathToTitle[path];
            break;
          }
        }
      }
    }

    document.title = pageTitle;
  }, [location]);

  return null; // This component does not render anything
};

export default PageTitle;
