import React from "react";
import { Grid, Container, FormControlLabel, Box, Typography } from "@mui/material";
import SubscriptionCard from "./SubscriptionCard";
import apiService from "../../service";
import { Radio } from '@mui/material';
import './Subscription.css';
import { useLocation } from "react-router-dom";




const SubscriptionPlans = () => {
    const [selectedCurrency, setSelectedCurrency] = React.useState("USD");
    const [subscriptionDatas, setSubscriptionDatas] = React.useState([])
    const location = useLocation();
    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
    };

    React.useEffect(() => {
        const fetchSubscriptions = async () => {
            try {
                const subscriptionResp = await apiService.getProductList();
                if (subscriptionResp.status_code === 200) {
                    setSubscriptionDatas(subscriptionResp.data);
                }
            } catch (error) {
                console.error("Error fetching subscriptions:", error);
            }
        };

        fetchSubscriptions();
    }, []);

    return (
        <div>
            <Grid container spacing={2} className="subscription-container">
            <Grid item xs={6}>
                <Typography variant="h4" gutterBottom className='subscription-text'>
                    Subscription Plans
                </Typography>
            </Grid>
            </Grid>
            <Grid container spacing={1}>
                {subscriptionDatas.map((plan) => (
                    <SubscriptionCard key={subscriptionDatas._id} plan={plan} selectedCurrency={selectedCurrency} />
                ))}
            </Grid>
        </div>

    );
};

export default SubscriptionPlans;
