// MyModal.js
import React, { useState, useEffect, useRef } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import axios from "axios";
import "./CriminalCertificateValidation.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import apiService from "./service";
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonComponent from "./components/ButtonComponent/Button";
import './Analyse.css'
import creditIcon from "./assets/CreditSvg.svg";
import {DynamicToastContent} from "./components/Common/ReactToastifyNotification/Toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Analyse = ({ isOpen, onClose, from ,fetchDataAsync}) => {
  console.log(from)
  const [files, setFiles] = useState(null);
  const [url, setUrl] = useState(""); //for file URl based upload
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [messageType, setMessageType] = useState(null);
  const timeoutRef = useRef(null);
  const [fileSizeError, setFileSizeError] = useState('')
  const userId = localStorage.getItem('userId')
  const navigate = useNavigate();
  const [request_id, setRequestId] = useState(null);
  const fileInputRef = useRef(null);
  const formRef = useRef(null);
  const [failedDocuments, setFailedDocuments] = useState([]);
  const [successfulDocuments, setSuccessfulDocuments] = useState([]);
  const [showToastMessage, setShowToastMessage] = useState(false);
  const location = useLocation();

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.submit();
    }
  };

  // useEffect(() => {
  //   return () => {
  //     setFileSizeError('')
  //   }
  // }, [])
  

  const handleApiCall = async (apiFunction, formData, successMessage, navigatePath) => {
    setLoading(true);
    setMessage("Processing...");
  
    timeoutRef.current = setInterval(() => {
      setMessage((prevMessage) => {
        const messages = [
          "Processing...",
          "Still analyzing...",
          "Hang tight, almost there...",
          "Just a moment more...",
          "Almost done...",
        ];
        return messages[(messages.indexOf(prevMessage) + 1) % messages.length];
      });
    }, 7000);
  
    try {
      const response = await apiFunction(formData);
      clearInterval(timeoutRef.current);
      if (!response) {
        throw new Error("Empty response from API");
      }
      const message = response?.data?.message || response?.message || null;
      console.log("Extracted Message:", message);
    
      if (message === "Please Recharge." || message === "Insufficient credit points") {
        localStorage.setItem("redirectLocation", location.pathname);
    
        const toastContent = (
          <DynamicToastContent
            icon={creditIcon}
            message={message}
            messageinfo="You need more credits to access this feature."
            actionText="Buy Credits"
            onActionClick={() => {
              navigate("/subscription");
              setShowToastMessage(false);
            }}
          />
        );
        toast(toastContent, {
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          autoClose: false,
          onClose: () => setShowToastMessage(false),
        });
        return;
      }
    
      // If API call was successful
      if (response?.status) {
        toast.success(successMessage);
        navigate(navigatePath);
        onClose && onClose();  
        // fetchDataAsync && fetchDataAsync(true, true);
      } else {
        toast.error(response?.message || "Unknown error occurred");
      }
    } catch (error) {
      toast.error("Error analyzing document. Please try again.");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if ((!files || files.length === 0) && (!url || url.trim() === "")) {
      toast.error("Please select at least one file or enter a valid URL");
      return;
    }
  
    const allowedTypes = ["application/pdf", "image/jpeg", "image/png"];
    if (files) {
      const invalidFiles = files.filter((file) => !allowedTypes.includes(file.type));
      if (invalidFiles.length > 0) {
        toast.error("Invalid file type. Please upload PDF, JPG, or PNG only.");
        return;
      }
    }
  
    const uploadPromises = files.map(async (file) => {
      const formData = new FormData();
      formData.append("files", file);
  
      switch (from) {
        case "aadhar-card":
          await handleApiCall(apiService.uploadFileAadhar, formData)
          break;
        case "invoice":
          await handleApiCall(apiService.analyseInvoice, formData)
          break;
        case "pan":
          await handleApiCall(apiService.analysePan, formData)
          break;
        case "bank-cheque":
          await handleApiCall(apiService.analysebankcheque, formData)
          break;
        case "eway-bill":
          await handleApiCall(apiService.analyseewaybill, formData)
          break;
        case "bank-passbook":
          await handleApiCall(apiService.analysePassbook, formData)
          break;
        case "receipt":
          await handleApiCall(apiService.analysereceipt, formData)
          break;
        case "gst-certificate":
          await handleApiCall(apiService.analyseGstScan, formData)
          break;
        case "business-card":
          await handleApiCall(apiService.analyseBusinessCard, formData)
          break;
        case "id-document":
          await handleApiCall(apiService.analyseIdDocument, formData)
          break;
        case "criminal-document":
          await handleApiCall(apiService.analyseCriminalCertificate, formData)
          break;
        case "general-document":
          await handleApiCall(apiService.analyseGenDocument, formData)
          break;
        default:
          toast.error("Invalid document type");
          return;
      }
    });
  
    await Promise.all(uploadPromises);
    fetchDataAsync && await fetchDataAsync(true, true);
    onClose();
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if(selectedFile) {
      const fileSize = selectedFile.size;
      const maxSize = 3 * 1024 * 1024; // 5 MB size limit

      if (fileSize > maxSize) {
        setFileSizeError('File size exceeds 5 MB');
        setFiles(null);
        setUrl('')
      } else {
        setFiles(Array.from(e.target.files));
        setFileSizeError('');
        // setFile(selectedFile);
      }
      
    }
    
  };
  const handleUrlChange = (event) => {
    console.log(event.target.value, "event")
    const enteredUrl = event.target.value;
    setUrl(enteredUrl);
    setFiles(null); // Clear file input if a URL is entered
  };

  const handleNextButtonClick = () => {
    setMessage("");
    setFiles([]);
    fileInputRef.current.value = ""; // Reset the file input value
    setFailedDocuments([]); // Clear failed documents
    setSuccessfulDocuments([]);
  };

  useEffect(() => {
    if (isOpen) {
      // Clear the state when the modal opens
      setFileSizeError('');
    }
  }, [isOpen]);

  const handleCopyFailedDocuments = () => {
    const failedDocumentsText = failedDocuments.join("\n");
    navigator.clipboard.writeText(failedDocumentsText);
    toast.success("Failed documents copied to clipboard");
  };

  const handleCopySuccessDocuments = () => {
    const successDocumentsText = successfulDocuments.join("\n");
    navigator.clipboard.writeText(successDocumentsText);
    toast.success("Successfully processed documents copied to clipboard");
  };

  return (
  <>
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <div className="ocr-form-container">
          <Box sx={style}>
            <Typography id="modal-title" variant="h6" component="h2">
              Analyse
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }}>
              <div style={{marginBottom: '20px'}}>
                <label className="label" htmlFor="fileInput">
                Note: You can upload a single  or multiple documents, but only in PDF format.
                </label>
                  <input
                    type="file"
                    style={{width : '100%'}}
                    onChange={handleFileChange}
                    multiple = {true}
                    ref={fileInputRef}
                    disabled={!!url.trim()}

                  />{" "}
                <Typography variant="h6" component="h2" id="model-text" sx={{textAlign: 'center', marginBottom: '5px'}} >
                  Or
                </Typography>
                <input
                  type="text"
                  style={{ width: "100%" , height: '40px', padding: '10px' , border: '1px solid #cccccc', borderRadius: '5px'}}
                  placeholder="Enter URL"
                  value={url}
                  onChange={handleUrlChange}
                  disabled={!!files}
                />
              </div>
              {/* <form ref={formRef} onSubmit={handleSubmit}>
                
              </form> */}
            </Typography>
            <div className="button-container">
              <Box>
              <ButtonComponent
              text="Cancel"
              onClick={()=>{
                onClose()
              }}
              className="excel-download"
              />
              {fileSizeError && <p style={{color : 'red'}}>File size is larger than 3 MB</p>}
              {loading && (
                <div className="loader-container">
                  <div className="loader"></div>
                  <span>{message}</span>
                </div>
              )}
              </Box>
              <Box>
              <ButtonComponent
              text="Analyse"
              onClick={handleSubmit}
              className="analyse-upload"
              />
              </Box>
            </div>
          </Box>
        </div>

        {/* Show success documents container if available */}
        {successfulDocuments.length > 0 && (
          <div className="successful-documents-container">
            <h3 className="successful-documents-header">
              Validated Documents
              <label
                className="copy-all-button-success"
                onClick={handleCopySuccessDocuments}
              >
                {" "}
                &nbsp; ❒
              </label>
            </h3>
            <ul className="successful-documents-list">
              {successfulDocuments.map((document, index) => (
                <li key={index}>{document}</li>
              ))}
            </ul>
          </div>
        )}

        {/* Show failed documents container if available */}
        {failedDocuments.length > 0 && (
          <div className="failed-documents-container">
            <h3 className="failed-documents-header">
              Failed Documents
              <label
                className="copy-all-button"
                onClick={handleCopyFailedDocuments}
              >
                {" "}
                &nbsp; ❒
              </label>
            </h3>
            <ul className="failed-documents-list">
              {failedDocuments.map((document, index) => (
                <li key={index}>{document}</li>
              ))}
            </ul>
          </div>
        )}
        {/* <div className="button-container">
          {message && (
            <Button
              className="next-button"
              onClick={handleNextButtonClick}
              variant="contained"
            >
              Analyse
            </Button>
          )}
        </div> */}
      </>
    </Modal>
    <ToastContainer/>
  </>
  );
};

export default Analyse;
