import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './Toastify.css';
import { Box, Typography } from "@mui/material";
import ButtonComponent from "../../ButtonComponent/Button";

const showToast = (type, message) => {
  toast[type](message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    className: "toastifyContainer",
  });
};

 const ToastMessage = {
  success: (message) => showToast("success", message),
  error: (message) => showToast("error", message),
  info: (message) => showToast("info", message),
  warning: (message) => showToast("warning", message),
};
export default ToastMessage;

export const DynamicToastContent = ({ icon, message, actionText, onActionClick, messageinfo }) => {
  return (
    <Box className="toast-container">
      {icon && typeof icon === "string" ? (
        <img
          src={icon}
          alt="Toast Icon"
          className="toast-icon"
        />
      ) : (
        icon && (icon, { style: { color: "#9B5CFD", fontSize: "2rem" } })
      )}
      <Box>
        <Typography className="ToastTitle">{message}</Typography>
        <Typography variant="caption">{messageinfo}</Typography>
        <div className="creditButtoncontainer">
        {actionText && onActionClick && (
          <ButtonComponent
            onClick={onActionClick}
            style={{ marginTop: "10px" }}
            text={actionText}
            className='credit-button'
            />
        )}
        </div>
      </Box>
    </Box>
  );
};
