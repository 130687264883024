import React, { useEffect, useState, useRef } from "react";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";
import "../../CriminalCertificateValidation.css";
import Tooltip from "@mui/material/Tooltip";
import Download from "../../assets/Download.svg";
import Plus from "../../assets/plus.svg";
import Analyse from "../../Analyse";
import apiService from "../../service";
import { useNavigate } from "react-router-dom";
import View from "../../assets/view.svg";
import Delete from "../../assets/delete.svg";
import DataGrid from "../GridDataComponent/CommonTable";
import ButtonComponent from "../ButtonComponent/Button";

const Bank = () => {

  const access = localStorage.getItem('access');
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    if (access.includes('gen-doc')) {
      setShow(true);
    }
  }, []);

  const navigate = useNavigate();
  const breadCrumbArray = [
    {
      name: "Bank Cheque",
      color: "#767676",
      underline: "none",
    },
  ];
  const [data, setData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);
  const [isAnalyseOpen, setIsAnalyseOpen] = useState(false);
  const [showUploadDropdown, setShowUploadDropdown] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleUploadDropdown = () => {
    setShowUploadDropdown(!showUploadDropdown);
  };

  const closeAnalysModal = () => {
    setIsAnalyseOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getbankcheque();
        console.log('response',response);
        setData(response);
        try {
          const data = response?.length > 0 ? response : [];
          setDataAvailable(data);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
          setDataAvailable([]); 
        }
        setShowDataGrid(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);
 
  
       
  

  const openAnalyseModal = () => {
    setIsAnalyseOpen(true);
  };
  const handleFileClick = (id, file_name) => {
    navigate(`/doc-ai/bank-cheque/preview/${id}`);
  };
  const addTooltipToCell = (params) => (
    <Tooltip
      title={params.value ? params.value.toString() : ""}
      arrow
      placement="left"
    >
      <span
        style={{ cursor: "pointer" }}
        className="file-name"
        onClick={() => handleFileClick(params.row.id, params.row.file_name)}
      >
        {params.value}
      </span>
    </Tooltip>
  );
  const renderActions = (params) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          // justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          src={View}
          onClick={() => handleFileClick(params.row.id, params.row.file_name)}
          alt="View"
          height={20}
        />
        <img
          style={{ cursor: "not-allowed" }}
          src={Delete}
          alt="Delete"
          height={20}
        />
      </div>
    );
  };

  const columns = [
    {field: 'id', headerName: 'Id', flex: 1},
    { field: 'fileName', headerName: 'File Name', flex: 1,
      renderCell: (params) => addTooltipToCell(params),
     },
     {
        field: 'uploadon',
        headerName: 'Upload on', // Example: Add a column for Account Number
        flex: 1,
      },
    // { field: 'extractedfiles', headerName: 'extracted Files', flex: 2 },
    { field: 'actions', headerName: 'Actions', flex: 1,
      renderCell: renderActions
    },
  ];
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // "short" for abbreviated month name
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  
  const rows = dataAvailable.map((item) => ({
    id: item.id,
    fileName: item.file_name, 
    uploadon: item.updated_at? formatDate(item?.updated_at) : '', 
  }));
 
  

  return (
    <>
      {show ? (
        <div className="page-container">
          <CustomBreadCrumbs array={breadCrumbArray} />
          <div className="ocr-form-container App">
            
            <div className="view-all-container">
            <div className="button-container-modal">
                                    <div className="button-container-end">
                                        <ButtonComponent
                                            onClick={() => console.log("Download Excel")}
                                            className="excel-download"
                                            icon={Download}
                                            iconAlt="Download"
                                            iconHeight={15}
                                            text="Export"
                                        />
                                        <ButtonComponent
                                            onClick={openAnalyseModal}
                                            buttonRef={buttonRef}
                                            className="analyse-upload"
                                            icon={Plus}
                                            iconAlt="Upload"
                                            iconHeight={15}
                                            text="Upload"
                                        />
                                    </div>
                                </div>

              <div className= "table" style={{ height: '100%', width: '100%' }}>
                <DataGrid

                  rows={rows}  
                  columns={columns}
                  pageSize={5}
                  rowsPerPageOptions={[5]}
                  localeText={{
                    MuiTablePagination: {
                      labelRowsPerPage: "Rows per page",
                    },
                  }}
                  disableColumnMenu={false}
                    disableColumnResize={false}
                disableAggregation={false}
                  />
                 
                
              </div>

              {/* Display Upload Dropdown */}
              {showUploadDropdown && (
                <div className="upload-dropdown" ref={dropdownRef}>
                  <h3>Drag and Drop to upload files</h3>
                  <p>PDF files not more than 5 MB</p>
                  <div className="upload-actions">
                    <button className="select-file-button">Select File</button>
                    <button
                      className="cancel-button"
                      onClick={toggleUploadDropdown}
                    >
                      Cancel
                    </button>
                    <button className="analyze-button">Analyze</button>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Analyse isOpen={isAnalyseOpen} onClose={closeAnalysModal} from="bank" />
        </div>
      ) : (
        <GeneralDocValidation />
      )}
    </>
  );
};

export default Bank;


