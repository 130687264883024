import React from 'react';
import './HawkaiTerms.css'
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import DrawerLogo from  "../../assets/DrawerLogo.png"

const Policy = () => {
    return (
        <div className='gradient-background'>
            <Box className="logo-box-terms">
                <Box>
                    <img src={DrawerLogo} alt="logo" className="logoImage-terms" />
                </Box>
            </Box>
            <div className="privacy-policy">
            <h1 style={{textAlign:"start"}}>Privacy Policy for HaawkAI</h1>
            <p style={{marginBottom:"40px"}} >Effective Date: 01-Mar-2025</p>
            <p style={{marginBottom:"40px"}}>
            HaawkAI (“we,” “our,” or “us”) is committed to protecting your
                privacy. This Privacy Policy outlines how we collect, use, store, and
                share your personal information when you access or use our web app
                (“the Web App”).
            </p>

            <h2 style={{marginTop:"40px"}}>1. Information We Collect</h2>

            <h3>1.1 Personal Information</h3>
            <ul>
                <li>
                    <strong>Account Information:</strong> When you create an account, we
                    collect your name, email address, password, and other registration
                    details.
                </li>
                <li>
                    <strong>Payment Information:</strong> For purchases, we collect
                    payment details, including credit card or payment method information,
                    through secure third-party payment processors.
                </li>
            </ul>

            <h3>1.2 Content Data</h3>
            <ul>
                <li>
                    <strong>Uploaded Images:</strong> Images you upload for AI
                    processing, including personal and product images, are temporarily
                    stored to generate the requested outputs.
                </li>
            </ul>

            <h3>1.3 Usage Information</h3>
            <ul>
                <li>
                    <strong>Device and Log Data:</strong> We collect information about
                    the device you use to access the Web App, including IP address,
                    browser type, operating system, and usage logs.
                </li>
                <li>
                    <strong>Cookies and Tracking Technologies:</strong> We use cookies
                    and similar technologies to enhance user experience and collect data
                    about how the Web App is accessed and used.
                </li>
            </ul>

            <h2>2. How We Use Your Information</h2>
            <ul>
                <li>
                    <strong>To Provide Services:</strong> Process your uploaded images,
                    generate AI-based outputs, and deliver requested services.
                </li>
                <li>
                    <strong>To Improve Our Platform:</strong> Analyze usage data to
                    improve the performance and features of the Web App.
                </li>
                <li>
                    <strong>To Communicate with You:</strong> Send updates, promotional
                    offers, and important notifications related to your account.
                </li>
                <li>
                    <strong>To Ensure Security:</strong> Protect the integrity and
                    security of our platform and prevent unauthorized access or abuse.
                </li>
            </ul>

            <h2>3. How We Share Your Information</h2>
            <h3>3.1 Service Providers</h3>
            <p>
                Third-party service providers, such as payment processors and cloud
                storage providers, to assist in delivering our services.
            </p>
            <h3>3.2 Legal Compliance</h3>
            <p>
                Authorities or regulators if required to comply with applicable laws,
                enforce our Terms and Conditions, or respond to lawful requests.
            </p>
            <h3>3.3 Aggregated Data</h3>
            <p>
                Anonymized and aggregated data may be shared for research, analytics,
                or improving our AI models.
            </p>

            <h2>4. Data Retention</h2>
            <ul>
                <li>
                    <strong>Uploaded Images:</strong> Images are temporarily stored only
                    for the duration necessary to generate the requested outputs and are
                    deleted promptly after processing.
                </li>
                <li>
                    <strong>Account Data:</strong> Retained as long as your account is
                    active or as required for legal or operational purposes.
                </li>
            </ul>

            <h2>5. Your Rights</h2>
            <ul>
                <li>
                    <strong>Access:</strong> Request access to the personal data we hold
                    about you.
                </li>
                <li>
                    <strong>Correction:</strong> Request corrections to any inaccurate or
                    incomplete data.
                </li>
                <li>
                    <strong>Deletion:</strong> Request deletion of your data, subject to
                    legal or contractual obligations.
                </li>
                <li>
                    <strong>Opt-Out:</strong> Opt out of promotional communications by
                    following the unsubscribe link in our emails.
                </li>
            </ul>

            <h2>6. Security of Your Information</h2>
            <p>
                We implement industry-standard security measures to protect your data
                from unauthorized access, loss, misuse, or alteration. However, no
                online platform is entirely secure, and we cannot guarantee absolute
                security.
            </p>

            <h2>7. Children’s Privacy</h2>
            <p>
            HaawkAI is not intended for children under the age of 13. We do not
                knowingly collect personal information from children. If we discover
                that a child’s data has been collected without parental consent, we
                will delete it promptly.
            </p>

            <h2>8. International Data Transfers</h2>
            <p>
                If you are accessing the Web App from outside [Insert Jurisdiction],
                your data may be transferred to and processed in a jurisdiction with
                different data protection laws. By using our platform, you consent to
                such transfers.
            </p>

            <h2>9. Cookies and Tracking Technologies</h2>
            <p>
                We use cookies to remember your preferences and settings, analyze
                traffic and usage patterns. You can control cookies through your
                browser settings, but disabling cookies may affect the functionality of
                the Web App.
            </p>

            <h2>10. Changes to this Privacy Policy</h2>
            <p>
                We may update this Privacy Policy from time to time. Any changes will
                be posted on this page with an updated "Effective Date." Continued use
                of the Web App after such changes indicates acceptance of the updated
                policy.
            </p>

            <h2>11. Contact Us</h2>
            <p>
                If you have any questions or concerns about this Privacy Policy, please
                contact us at: <a href="mailto:support@HaawkAI.io">support@HaawkAI.com</a>.
            </p>

            <p>By using HaawkAI you agree to the terms outlined in this Privacy Policy.</p>
        </div>
        </div>

    )
}

export default Policy;