// src/services/apiService.js
import axios from 'axios';

const HttpClient = axios.create();

const API_URL = process.env.REACT_APP_API_URL;
const API_VERSION = process.env.REACT_APP_API_VERSION;
console.log(API_URL)



HttpClient.interceptors.request.use(
  (config) => {
    const tenant = localStorage.getItem('tenant');
    const user = localStorage.getItem('userId');
    if (tenant) {
      config.headers.tenant = `${tenant}`;
      config.headers.user = `${user}`;
    }
    console.log('Axios Request Config:', config)
    return config;
  },
  (error) => Promise.reject(error)
);

const getDataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/AIController/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getData = async (userId) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/AIController/all/${userId}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const analyzeData = async (formData, userId) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/v1/analyze`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      params: {
        "user_id": userId
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

const analyseInvoice = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/invoice/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const analyseGenDocument = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/general-document/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const analyseCriminalCertificate = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/myai/criminal-document/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const login = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/api/login`, data, {
      headers: {
        'Content-Type': 'application/json',
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error.response.data;
  }
};

//Criminal Certificates
const getCriminalCertificate = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/myai/criminal-documents`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getCriminalCertificateDataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/myai/criminal-document/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getInvoice = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/invoices`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getInvoiceDataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/invoice/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getGenDocuments = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/general-documents`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getGenDocumentDataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/general-document/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Aadhar card section
const getAadhar = async () => {
 try{
  const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/aadhars`, {
    headers: { 'Content-Type': 'application/json' },
  });
  return response.data;
 }catch (error) {
  console.error('Error fetching data:', error);
  throw error;
}
}

const getAadharDataById = async (id) => {
  try{
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/aadhar/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const uploadFileAadhar = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/aadhar/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response.data;
  }
  catch (error) { 
    console.error('Error posting data:', error);
    throw error;
  }
}

// Pan card section
const getPan = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/pans`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getpandataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/pan/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const analysePan = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/pan/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
//bank cheque section
const getbankcheque = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/bank-cheques`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getbankchequedataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/bank-cheque/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const analysebankcheque = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/bank-cheque/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
//eway Bill Section
const getewaybill = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/eway-bills`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getewaybilldataById = async (id) => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/eway-bill/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const analyseewaybill = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/eway-bill/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

//GST section
const getGST = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/gst-certifications`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getGstDataId = async (id) => {
  try{
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/gst-certification/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const analyseGstScan = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/gst-certification/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const getreceipt = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/receipts`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getreceiptDataId = async (id) => {
  try{
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/receipt/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const analysereceipt = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/receipt/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};


//Business card section
const getBusinessCard = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/business-cards`, {
      headers: { 'Content-Type': 'application/json' },
    });
    console.log("responseAPI", response)
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getBusinessCardId = async (id) => {
  try{
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/business-card/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const analyseBusinessCard = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/business-card/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    console.log(response, "APISCAN")
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}

//id documents here
const getIDDocument = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/id-documents`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getIDDocumentDataID = async (id) => {
  try{
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/id-document/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const analyseIdDocument = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/id-document/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}
//passbook section
const getpassbook = async () => {
  try {
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/bank-passbooks`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getPassBookBYID = async (id) => {
  try{
    const response = await HttpClient.get(`${API_URL}/api/${API_VERSION}/bank-passbook/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    });
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const analysePassbook = async (formData) => {
  try {
    const response = await HttpClient.post(`${API_URL}/api/${API_VERSION}/bank-passbook/scan`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    });
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}
const apiService = {
  getDataById,
  getData,
  analyzeData,
  login,
  getInvoice,
  getInvoiceDataById,
  analyseInvoice,
  analyseGenDocument,
  getGenDocuments,
  getGenDocumentDataById,
  getCriminalCertificate,
  getCriminalCertificateDataById,
  analyseCriminalCertificate,
  getPan,
  getpandataById,
  analysePan,
  getAadhar,
  uploadFileAadhar,
  getAadharDataById,
  getbankcheque,
  getbankchequedataById,
  analysebankcheque,
  getewaybill,
  getewaybilldataById,
  analyseewaybill,
  getGST,
  getGstDataId,
  analyseGstScan,
  getBusinessCard,
  getBusinessCardId,
  analyseBusinessCard,
  getIDDocument,
  getIDDocumentDataID,
  analyseIdDocument,
  getreceipt,
  getreceiptDataId,
  analysereceipt,
  getpassbook,
  getPassBookBYID,
  analysePassbook
};

export default apiService;