import React, { useEffect, useState } from "react";
import { Document, Page } from "react-pdf";

import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const PDFfile = ({ imageBlobUrls }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const onDocumentLoadError = (error) => {
    setError(error.message);
  };
  console.log("PDF URL:", imageBlobUrls)

  return (
    <div>
      {imageBlobUrls && imageBlobUrls[0] ? (
        <Document
          file={imageBlobUrls[0]}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onDocumentLoadError}
        >
          <Page
            pageNumber={pageNumber}
            // width={width}
            // height={height}
          />
        </Document>
      ) : (
        <p>Loading PDF...</p>
      )}
    </div>
  );
};

export default PDFfile;
