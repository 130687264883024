import React from "react";

const ButtonComponent = ({
  type,
  onClick,
  buttonRef,
  icon,
  iconAlt,
  iconHeight,
  text,
  className,
  style,
}) => {
  return (
    <button ref={buttonRef} onClick={onClick} className={className} style={style} type={type}>
      {icon && (
        <img
          src={icon? icon : null}
          alt={iconAlt}
          height={iconHeight ? iconHeight : null}
          style={{ marginRight: 5 }}
        />
      )}
      {text}
    </button>
  );
};

export default ButtonComponent;
