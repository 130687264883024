import React, { useEffect } from "react";
import {Grid, Typography} from "@mui/material";
import GridUsageDetails from "./UsageDetails";
import { useNavigate } from "react-router-dom";
import ShowToast from "../Common/ReactToastifyNotification/Toastify"



function MyUsage() {

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h5" className="usageTitle">My Usage</Typography>
            </Grid>
           {/* Professional Sections */}

           {/* Chart Sections */}

            {/* Usage Details */}
           <Grid item xs={12}>
             <GridUsageDetails />
            </Grid>
        </Grid>
    );
}

export default MyUsage;
