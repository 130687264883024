import React from "react";
import { Grid, Paper, Box, Typography, Button } from "@mui/material";
import ButtonComponent from "../ButtonComponent/Button";
import apiService from "../../service";

const SubscriptionCard = ({ plan, selectedCurrency }) => {

  const handleCheckoutSession = async() => {
    const userId = localStorage.getItem("userId");
    const tenantId = localStorage.getItem("tenantId");
   
    if(selectedCurrency === 'USD'){
      const obj = {
        "userId":userId,
        "tenant_id":tenantId,
        "stripe_product_id":plan.stripe_product_id,
        "stripe_product_price_id":plan.stripe_product_price_id,
        "productName":plan.name,
        "quantity":1,
        "currency":"usd",
        "unit_amount":plan.totalCost,
        "total_amount":plan.totalCost,
        "credit_points" : plan.totalCredit
      }  
      const createCheckoutSessionResponse = await apiService.createCheckoutSession(obj);
      if(createCheckoutSessionResponse){
        window.location.href = createCheckoutSessionResponse.sessionUrl;
      }
    }
   
    // if() 
  }
  return (
    <Grid item xs={12} sm={12/2} md={12/3}  lg={12/5}>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          maxWidth: 300,
          textAlign: "start",
          border: "2px solid #000",
          backgroundColor: "transparent",
          borderRadius: 3,
          height: "500px",
          position: "relative",
          color: "black",
        }}
      >
        <Typography variant="h5" fontWeight="bold" gutterBottom>
          {plan.name}
        </Typography>

        <Typography variant="h4" gutterBottom>
          {selectedCurrency === "INR" ? `₹${plan.totalInrCost}` : `$${plan.totalCost}`}
        </Typography>

        <Typography variant="body1" gutterBottom>
          {plan.description || ""}
        </Typography>

        {plan.name !== "Enterprise" && (
           <ButtonComponent
           onClick={()=>handleCheckoutSession(plan)}
           className="analyse-upload"
           text="Get Started"
         />
        )}

        <Box sx={{ mt: 2, textAlign: "left" }}>
          {plan.features.map((feature, index) => (
            <Typography key={index} variant="body2" sx={{ mb: 1 }}>
              ✓ {feature}
            </Typography>
          ))}
        </Box>
      </Paper>
    </Grid>
  );
};

export default SubscriptionCard;
