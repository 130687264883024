import React from "react";
import { Box, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import DrawerLogo from "../../assets/DrawerLogo.png";
import "./HawkaiTerms.css"; 

const TermsAndConditions = () => {
  const navigate = useNavigate();

  return (
    <div className="gradient-background">
      {/* Logo Section */}
      <Box className="logo-box-terms">
        <img src={DrawerLogo} alt="Haawk-Ai Logo" className="logoImage-terms" />
      </Box>

      {/* Terms and Conditions Content */}
      <Container className="privacy-policy">
        <h1>Terms and Conditions for Haawk-Ai</h1>
        <p><strong>Effective Date:</strong> 01-Mar-2025</p>

        <p>
          Welcome to Haawk-Ai ("the Web App"). By accessing or using our platform,
          you agree to be bound by the following Terms and Conditions. If you do not
          agree to these terms, you may not use the platform.
        </p>

        <h3>1. Acceptance of Terms</h3>
        <p>
          By registering for or using Haawk-Ai, you agree to comply with and be
          legally bound by these Terms and Conditions.
        </p>

        <h3>2. Services Provided</h3>
        <ul>
          <li>Upload documents and utilize AI to generate relevant images.</li>
          <li>Enhance document-based visuals with AI-powered image generation.</li>
        </ul>

        <h3>3. Usage of AI Technology</h3>
        <h6>3.1 AI Output Disclaimer</h6>
        <p>
          AI-generated images are provided "as is" and based on user input. Haawk-Ai
          cannot guarantee accuracy.
        </p>

        <h4>3.2 User Responsibility</h4>
        <p>
          Users must ensure their inputs comply with laws and do not infringe on third-party rights.
        </p>

        <h4>3.3 Content Rights and Usage</h4>
        <p>
          By uploading content, users grant Haawk-Ai a non-exclusive license to process and use the content for AI generation.
        </p>

        <h3>4. Payment and Refund Policy</h3>
        <h4>4.1 Credits and Payments</h4>
        <p>
          Users must purchase credits to use certain features. All payments are final.
        </p>

        <h4>4.2 No Refunds</h4>
        <p>
          Payments are non-refundable, even if results do not meet expectations.
        </p>

        <h3>5. User Conduct</h3>
        <ul>
          <li>Do not upload illegal or copyrighted content.</li>
          <li>Do not attempt to exploit or reverse-engineer the AI.</li>
        </ul>

        <h3>6. Privacy Policy</h3>
        <p>
          See our <span className="link" onClick={() => navigate("/privacy-policy")}>
            Privacy Policy
          </span> for details.
        </p>

        <h3>7. Intellectual Property</h3>
        <p>
          HaawkAI retains ownership of its technology, algorithms, and methodologies.
        </p>

        <h3>8. Limitation of Liability</h3>
        <p>
          HaawkAI is not responsible for any damages resulting from platform use.
        </p>

        <h3>9. Termination</h3>
        <p>
          We may suspend or terminate access for violations of these Terms.
        </p>

        <h3>10. Governing Law</h3>
        <p>
          These Terms are governed by the laws of Karnataka, India.
        </p>

        <h3>11. Changes to Terms</h3>
        <p>
          We may update these Terms at any time. Continued use signifies acceptance.
        </p>

        <h3>12. Contact Us</h3>
        <p>
          For support, contact us at <a href="mailto:support@Haawk-Ai.io" className="link">support@Haawk-Ai.io</a>.
        </p>

        <p>
          By using Haawk-Ai, you agree to these Terms and Conditions.
        </p>
      </Container>
    </div>
  );
};

export default TermsAndConditions;
