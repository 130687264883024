import React, { useEffect } from 'react';
import { Grid, Box, Typography, Divider, IconButton, TextField, InputAdornment } from '@mui/material';
import './Profile.css';
import Edit from "../../assets/Edit.svg";
import Return from "../../assets/return.svg";
import CopyIcon from "../../assets/copy.svg";
import ButtonComponent from '../ButtonComponent/Button';
import Eye from "../../assets/eye.svg";
import EyeOff from "../../assets/view-off.svg";
import apiService from '../../service';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ToastMessage from '../Common/ReactToastifyNotification/Toastify';
import { useNavigate } from 'react-router-dom';
import { useLogout } from '../../context/LogoutContext';
import { CloseOutlined } from "@mui/icons-material";


function Profile() {
    const [showPassword, setShowPassword] = React.useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
    const [formData, setFormData] = React.useState({
        password: "",
        currentPassword: "",
    });
    const [apiKey, setApiKey] = React.useState(localStorage.getItem('api_key') || '');
    const [showApiKey, setShowApiKey] = React.useState(false);
    const [modalOpen, setModalOpen] = React.useState(false);
    const navigate = useNavigate();
    const name = localStorage.getItem('name') || '';
    const email = localStorage.getItem('email') || '';
    const { logout } = useLogout();

    const handleModalClick = () => {
        setModalOpen(true);
    };
    useEffect(()=>{
        if(!localStorage.getItem('token')){
            navigate('/login');
        }
    },[])

    const passwordRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    //change Password
    const handleChangePassword = async () => {
        console.log("Change Password", formData);
    
        if (!passwordRegex.test(formData.password)) {
            console.error("Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, a number, and a special character.");
            return;
        }
    
        try {
            const payLoad = {
                email: email,
                current_password: formData.currentPassword,
                new_password: formData.password 
            };
            
            const changeRespon = await apiService.changePassword(payLoad);
            
            if (changeRespon && changeRespon.status_code === 200) {
                console.log("Password changed successfully");
                setFormData({ ...formData, currentPassword: "", password: "" });
                ToastMessage.success(changeRespon.message);
               await logout();
            } else {
                console.error("Password change failed:", changeRespon?.message);
            }
        } catch (error) {
            console.error("Password change request failed", error);
        }
    };
    //apiKey generate
    const handleGenerateApikey = async () => {
        try {
            const apikeyResp = await apiService.apiKeyGenerate();
            const newApiKey = apikeyResp?.user?.api_key;
    
            if (newApiKey) {
                setApiKey(newApiKey);
                localStorage.setItem('api_key', newApiKey);
                ToastMessage.success(apikeyResp?.message);
            } else {
                throw new Error("Invalid API Key received");
            }
        } catch (error) {
            console.error("API Key generation failed", error);
            ToastMessage.error("Failed to generate API Key. Please try again.");
        }
    };
    
    //copy to clipboard
    const handleCopyToClipboard = () => {
        if (navigator.clipboard) {
          if (apiKey.length > 0) {
            navigator.clipboard.writeText(apiKey);
            ToastMessage.success("API Key copied to clipboard");
          } else {
            ToastMessage.warning("API Key is empty to copy to clipboard");
          }
        } else {
          ToastMessage.error("Clipboard API not available");
        }
      };
    
    

    const profileSections = [
        { label: "Name", value: name, 
            // button: { text: "Edit", icon: Edit } 
        },
        { label: "Email ID", value: email },
        { 
            label: "Password", 
            value: formData.currentPassword || formData.password ? "*".repeat(formData.currentPassword.length || formData.password.length) : "********", 
            button: { text: "Change", icon: Return, onClick: handleModalClick }  
        },
        { label: "Timezone", value: "UTC +5:30 IST, Bengaluru, India", 
            // button: { text: "Edit", icon: Edit } 
        },
    ];
    
    



    return (
        <Grid container spacing={2} className="mainProfile">
            <Box className="profileTitleContainer">
                <Typography variant="h5" className="profileTitle">My Profile</Typography>
            </Box>

            {profileSections.map((section, index) => (
                <React.Fragment key={section.label}>
                    <Box className="profileContainer">
                        <Box className="profileSub">
                            <Typography variant="h6" className="profileSubTitle">{section.label}</Typography>
                        </Box>
                        <Box className="profileSu2">
                            <Typography variant="h6" className="profileSubTitle">{section.value}</Typography>
                        </Box>
                        <Box className="profileSub">
                            {section.button && (
                                <ButtonComponent
                                    onClick={() => {
                                        if (section.label === "Password") {
                                            setModalOpen(true);  // Open modal
                                        }
                                    }}
                                    className="buttonCommon"
                                    icon={section.button.icon}
                                    iconAlt={section.button.text}
                                    iconHeight={15}
                                    text={section.button.text}
                                />
                            )}
                            {modalOpen && (
                                <Dialog open={modalOpen} onClose={() => setModalOpen(false)} className='profileDialog'>
                                    <div className="closeicon">
                                          <CloseOutlined className="close-icon" onClick={()=>setModalOpen(false)} />
                                          </div>
                                    <DialogTitle>Change Password</DialogTitle>
                                    <DialogContent>
                                        <TextField
                                            value={formData.currentPassword}
                                            className="textField"
                                            type={showPassword ? "text" : "password"}
                                            label="Current Password"
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                currentPassword: e.target.value
                                            }))}
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowPassword(prev => !prev)}>
                                                            <img src={showPassword ? EyeOff : Eye} alt={showPassword ? "Hide" : "Show"} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                        <TextField
                                            value={formData.password}
                                            className="textField"
                                            label="New Password"
                                            type={showCurrentPassword ? "text" : "password"}
                                            onChange={(e) => setFormData(prevData => ({
                                                ...prevData,
                                                password: e.target.value
                                            }))}
                                            fullWidth
                                            margin="dense"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={() => setShowCurrentPassword(prev => !prev)}>
                                                            <img src={showCurrentPassword ? EyeOff : Eye} alt={showCurrentPassword ? "Hide" : "Show"} />
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </DialogContent>
                                    <DialogActions>
                                        <div className='deletebutton'>
                                        <ButtonComponent onClick={() => setModalOpen(false)} className="cancel-button" text="Cancel" />
                                        <ButtonComponent onClick={()=>handleChangePassword()} className="analyse-upload" text="Change" />
                                        </div>
                                    </DialogActions>
                                </Dialog>
                            )}
                        </Box>
                    </Box>
                    {index !== profileSections.length - 1 && <Divider className="dividerMenu" />}
                </React.Fragment>
            ))}

            {/* Integrations Section */}
            <Divider className="dividerMenu" />
            <Box className="profileContainer">
                <Box className="profileSub">
                    <Typography variant="h6" className="apikeytitle">Integrations</Typography>
                </Box>
            </Box>

            <Box className="apikeyContainer">
                <Box className="profileSub">
                    <Typography variant="h6" className="profileSubTitle">API Key</Typography>
                </Box>

                <TextField
                    value={apiKey}
                    className="textFields"
                    type={showApiKey ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowApiKey(prev => !prev)}>
                                    <img src={showApiKey ? EyeOff : Eye} alt={showApiKey ? "Hide" : "Show"} />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <IconButton onClick={() => handleCopyToClipboard()}>
                    <img src={CopyIcon} alt="Copy" />
                </IconButton>
                <ButtonComponent
                    onClick={() => handleGenerateApikey()}
                    className="common-button"
                    text={apiKey ? "Regenerate" : "Generate"}
                />
            </Box>
        </Grid>
    );
}

export default Profile;