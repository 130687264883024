import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";
import "./Drawer.css";
import logo from "../../assets/logo.svg"; // Replace with the path to your logo
import MainLogo from "../../assets/Hawkai-logo.png";
import custom from "../../assets/custom.svg";
import general from "../../assets/clipboard.svg";
import vision from "../../assets/vison-ai.svg"; // Replace with the path to your Vision AI icon
import DrawerLogo from  "../../assets/DrawerLogo.png";
import { Popover, Tooltip } from "@mui/material";
import menuItem from "../../assets/menu-05.svg"
import { CloseOutlined } from "@mui/icons-material";

const drawerWidth = 260;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "space-between",
}));

const StyledListItemButton = styled(ListItemButton)(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: "#EFEFEF",
    "&:hover": {
      backgroundColor: "#EFEFEF",
    },
  }),
}));

export default function DrawerComponent({ open, handleDrawerToggle }) {
  const location = useLocation();
  const [expanded, setExpanded] = useState({});
  const [show, setShow] = useState(false);
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [popoverContent, setPopoverContent] = useState([]);
  const [popoverTitle, setPopoverTitle] = useState("");

  const handlePopoverOpen = (event, submenu) => {
   if(!open){
    setPopoverAnchor(event.currentTarget);
    setPopoverContent(submenu);
   }
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
    setPopoverContent([]);
  };

  const handlePopoverClick = (event, title, submenu) => {
    if (!open) {
      setPopoverTitle(title);
      handlePopoverOpen(event, submenu);
    } else {
      handleToggle(title.toLowerCase().replace(" ", "-"));
    }
  };

  // Initialize expanded state based on the current route
  useEffect(() => {
    const path = location.pathname;
    setExpanded({
      "custom-doc-validation": path.startsWith("/my-ai"),
      "doc-ai": path.startsWith("/doc-ai"),
      "vision-ai": path.startsWith("/vision-ai"),
    });
  }, [location.pathname]);

  const handleToggle = (item) => {
    if (!item) return; 
    setExpanded((prev) => ({
      ...prev,
      [item]: !prev?.[item],
    }));
  };

  const accessList = localStorage.getItem("access") || "";

  useEffect(()=>{
    if(accessList.includes("IDCriminalDocument")){
      setShow(true)
    }else{
      setShow(false)
    }
  },[accessList])

  // TextElips
  const truncateText = (text, charLimit = 22) => {
    return text.length > charLimit ? text.slice(0, charLimit) + "..." : text;
  };
  

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? drawerWidth : 60,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : 60,
          boxSizing: "border-box",
          transition: "width 0.3s",
        },
      }}
    >
      <DrawerHeader>
        {open && <img src={DrawerLogo} alt="Logo" style={{ height: 40 }} />}
        <IconButton onClick={handleDrawerToggle}>
         <img src={menuItem} alt="menuItem" style={{ height: 21 }} />
        </IconButton>
      </DrawerHeader>
      <List>
        {/* My AI Menu */}
       {show &&
       <>
        <ListItem disablePadding>
          <StyledListItemButton
            component={Link}
            // to="/custom-document-validation"
            // selected={location.pathname.startsWith("/my-ai")}
            onClick={(event) => handlePopoverClick(event, "custom-doc-validation", [
              { path: "/my-ai/criminal-document", label: "Criminal Certificate" },
              // Add more submenu items here if needed
            ])}
          >
            <ListItemIcon>
              <img src={custom} alt="Custom" style={{ height: 20 }} />
            </ListItemIcon>
            {open && <ListItemText primary="My AI" className="primaryText" />}
            {open &&
              (expanded["custom-doc-validation"] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
          </StyledListItemButton>
        </ListItem>
          {open && 
            <Collapse
            in={expanded["custom-doc-validation"]}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
                <StyledListItemButton
                  component={Link}
                  to="/my-ai/criminal-document"
                  selected={location.pathname.startsWith(
                    "/my-ai/criminal-document"
                  )}
                >
                  {open && <ListItemText secondary={
                    <Tooltip title={"Criminal Certificate"}>
                      <ListItemText secondary={truncateText("Criminal Certificate" , 22)} />
                    </Tooltip>
                  } />}
                </StyledListItemButton>
              </ListItem>
            </List>
          </Collapse>
          }
       </>
       }

        {/* Doc AI Menu */}
        <ListItem disablePadding>
          <StyledListItemButton
            component={Link}
            // to="/doc-ai"
            // selected={location.pathname.startsWith("/doc-ai")}
            onClick={(event) => handlePopoverClick(event, "Doc AI", [
              { path: "/doc-ai/invoice", label: "Invoice" },
              { path: "/doc-ai/aadhar-card", label: "Aadhar Card" },
              { path: "/doc-ai/gst-certificate", label: "GST Certificate" },
              { path: "/doc-ai/business-card", label: "Business Card" },
              { path: "/doc-ai/credit-card", label: "Credit Card" },
              { path: "/doc-ai/id-document", label: "ID Document" },
              { path: "/doc-ai/pan", label: "PAN" },
              { path: "/doc-ai/bank-cheque", label: "Bank Cheque" },
              { path: "/doc-ai/eway-bill", label: "Eway Bill" },
              { path: "/doc-ai/receipt", label: "Receipt" },
              { path: "/doc-ai/bank-passbook", label: "Bank Passbook" },
              { path: "/doc-ai/general-document", label: "General Document" },
            ])}
          >
            <ListItemIcon>
              <img src={general} alt="General" style={{ height: 20 }} />
            </ListItemIcon>
            {open && <ListItemText primary="Doc AI" className="primaryText" />}
            {open &&
              (expanded["doc-ai"] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
          </StyledListItemButton>
        </ListItem>
       {open &&
        <Collapse in={expanded["doc-ai"]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/invoice"
              selected={location.pathname.startsWith("/doc-ai/invoice")}
            >
              {open && <ListItemText secondary="Invoice" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/aadhar-card"
              selected={location.pathname.startsWith("/doc-ai/aadhar-card")}
            >
              {open && <ListItemText secondary="Aadhar Card" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/gst-certificate"
              selected={location.pathname.startsWith("/doc-ai/gst-certificate")}
            >
              {open && <ListItemText secondary={
                // <Tooltip title={"GST Certificate"}>
                  <ListItemText secondary={truncateText("GST Certificate" , 22)} />
                // </Tooltip>
              } />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/business-card"
              selected={location.pathname.startsWith("/doc-ai/business-card")}
            >
              {open && <ListItemText secondary={
                // <Tooltip title={"Business Card"}>
                  <ListItemText secondary={truncateText("Business Card" , 22)} />
                // </Tooltip>
              } />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/credit-card"
              selected={location.pathname.startsWith("/doc-ai/credit-card")}
            >
              {open && <ListItemText secondary="Credit Card" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/id-document"
              selected={location.pathname.startsWith("/doc-ai/id-document")}
            >
              {open && <ListItemText secondary="ID Document" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/pan"
              selected={location.pathname.startsWith("/doc-ai/pan")}
            >
              {open && <ListItemText secondary="PAN" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/bank-cheque"
              selected={location.pathname.startsWith("/doc-ai/bank-cheque")}
            >
              {open && <ListItemText secondary="Bank Cheque" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/eway-bill"
              selected={location.pathname.startsWith("/doc-ai/eway-bill")}
            >
              {open && <ListItemText secondary="Eway Bill" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/receipt"
              selected={location.pathname.startsWith("/doc-ai/receipt")}
            >
              {open && <ListItemText secondary="Receipt" />}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/bank-passbook"
              selected={location.pathname.startsWith("/doc-ai/bank-passbook")}
            >
              {open && <ListItemText secondary={
                // <Tooltip title={"Bank Passbook"}>
                  <ListItemText secondary={truncateText("Bank Passbook" , 22)} />
                // </Tooltip>
              }/>}
            </StyledListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
            <StyledListItemButton
              component={Link}
              to="/doc-ai/general-document"
              selected={location.pathname.startsWith("/doc-ai/general-document")}
            >
              {open && <ListItemText secondary={
                // <Tooltip title={"General Document"}>
                  <ListItemText secondary={truncateText("General Document" , 22)} />
                // </Tooltip>
              } />}
            </StyledListItemButton>
          </ListItem>
        </List>
      </Collapse>
       }

        {/* Vision AI Menu */}
        <ListItem disablePadding>
          <StyledListItemButton
            component={Link}
            // to="/vision-ai"
            // selected={location.pathname.startsWith("/vision-ai")}
            onClick={(event) => handlePopoverClick(event, "Vision AI", [
              { path: "/vision-ai/face-detection", label: "Face Detection" },
              { path: "/vision-ai/liveness-detection", label: "Liveness Detection" },
              { path: "/vision-ai/product-deduction", label: "Product Deduction" },
              { path: "/vision-ai/image-search", label: "Image Search" },
              { path: "/vision-ai/video-search", label: "Video Search" },
              { path: "/vision-ai/video-summary", label: "Video Summary" },
            ])}
          >
            <ListItemIcon>
              <img src={vision} alt="Vision AI" style={{ height: 20 }} />
            </ListItemIcon>
            {open && <ListItemText primary="Vision AI"  className="primaryText"/>}
            {open &&
              (expanded["vision-ai"] ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              ))}
          </StyledListItemButton>
        </ListItem>
        {open &&
          <Collapse in={expanded["vision-ai"]} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/face-detection"
                selected={location.pathname.startsWith("/vision-ai/face-detection")}
              >
                {open && <ListItemText secondary={
                  // <Tooltip title={"Face Detection"}>
                    <ListItemText secondary={truncateText("Face Detection" , 22)} />
                  // </Tooltip>
                } />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/liveness-detection"
                selected={location.pathname.startsWith("/vision-ai/liveness-detection")}
              >
                {open && <ListItemText secondary={
                  // <Tooltip title={"Liveness Detection"}>
                    <ListItemText secondary={truncateText("Liveness Detection" , 22)} />
                  // </Tooltip>
                } />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/product-deduction"
                selected={location.pathname.startsWith("/vision-ai/product-deduction")}
              >
                {open && <ListItemText secondary={
                  // <Tooltip title={"Product Deduction"}>
                    <ListItemText secondary={truncateText("Product Deduction" , 22)} />
                  // </Tooltip>
                } />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/image-search"
                selected={location.pathname.startsWith("/vision-ai/image-search")}
              >
                {open && <ListItemText secondary={
                  // <Tooltip title={"Image Search"}>
                    <ListItemText secondary={truncateText("Image Search" , 22)} />
                  // </Tooltip>
                } />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/video-search"
                selected={location.pathname.startsWith("/vision-ai/video-search")}
              >
                {open && <ListItemText secondary={
                  // <Tooltip title={"Video Search"}>
                    <ListItemText secondary={truncateText("Video Search" , 22)} />
                  // </Tooltip>
                } />}
              </StyledListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ pl: open ? 4 : 0 }}>
              <StyledListItemButton
                component={Link}
                to="/vision-ai/video-summary"
                selected={location.pathname.startsWith("/vision-ai/video-summary")}
              >
                {open && <ListItemText secondary={
                  // <Tooltip title={"Video Summary"}>
                    <ListItemText secondary={truncateText("Video Summary" , 22)} />
                  // </Tooltip>
                } />}
              </StyledListItemButton>
            </ListItem>
          </List>
        </Collapse>
}
      </List>

      { !open && 
      <Popover
      open={Boolean(popoverAnchor)}
      anchorEl={popoverAnchor}
      onClose={handlePopoverClose}
      anchorOrigin={{ vertical: "center", horizontal: "right" }}
      transformOrigin={{ vertical: "center", horizontal: "left" }}
    >
      <List>
      <ListItem>
    <ListItemText primary={popoverTitle === 'custom-doc-validation' ? "My AI" : popoverTitle} className="primaryText"/>
  </ListItem>
        {popoverContent.map((item) => (
          <ListItem key={item.path} disablePadding>
            <ListItemButton component={Link} to={item.path} onClick={handlePopoverClose}
             selected={location.pathname === item.path}
            >
              <ListItemText secondary={item.label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Popover>
      }
    </Drawer>
  );
}
