import React, {useState, useEffect } from "react";
import apiService from "../../service";
import { useParams } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import PreviewComponent from "../Preview/Preview";

const GstPreview = () => {
  const { id } = useParams();
  const [dataAvailable, setDataAvailable] = useState(null);

  //Gst Preview  get api calling here
  useEffect(() => {
    async function fetchData() {
      try {
        const data = await apiService.getGstDataId(id);
        setDataAvailable(data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  const breadCrumbArray = [
    {
      name: "Gst Certificate",
      color: "#000000",
      underline: "none",
      href: "/doc-ai/gst-certificate",
    },
    {
      name: dataAvailable?.file_name,
      color: "#767676",
      underline: "none",
    },
  ];

  return (
    <>
      <PreviewComponent
        dataAvailable={dataAvailable}
        setDataAvailable={setDataAvailable}
        breadCrumbNames={breadCrumbArray}
        id={id}
      />
    </>
  );
};

export default GstPreview;
