
import DocumentDetails from "../GridDataComponent/DocumentDetails";
import "./aadharCard.css";
import "../../CriminalCertificateValidation.css";

const AadharcardDetails = () => {
  return (
    <DocumentDetails documentType={'aadhars'} title={"Aadhar Card"} keyAccess="HawkAI-Aadhar-V2" exportDataToExcelName={"Aadhar Document"} analysefrom={'aadhar-card'} deleteText={'aadhar'} modelName={'doc-ai'} webSocketName = {'aadhar'} />
  );
};

export default AadharcardDetails;