// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiListItemIcon-root {
    min-width: 33px !important;
  }

  .css-11biola-MuiDrawer-docked .MuiDrawer-paper {
    background: #F9F9F9;
  }
  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected, .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.Mui-selected {
    background: #EFEFEF !important;
    border-radius: 10px;
  }
  .MuiTypography-root.MuiListItemText-secondary{
    font-family: "Public sans";
    color: #000000;
    font-weight: 400;
    font-size: 14px;
  }
.MuiListItemText-root.primaryText .MuiTypography-root{
    font-weight: 600;
    font-family: "Public sans";
    font-size: 18px;
    color: #000000;
  }
  .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.Mui-selected .MuiListItemText-root .MuiTypography-root.MuiListItemText-secondary{
    font-family: "Public sans";
    color: #000000;
    font-weight: 600;
    font-size: 14px;
  }
 
  /* .css-1s5q7ck-MuiListItem-root {
    height: 32px;
    border-radius: 10px;
  }
  .css-1s5q7ck-MuiListItem-root :hover {
    height: 30px;
  }
  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    height: 30px;
  } */
  .MuiPopper-root.popper{
    z-index: 100;
    top: 10%;
    left: 20%;
  }
  /* ::-webkit-scrollbar{
    width: 5px;
  }
  ::-webkit-scrollbar-track{
    background: #F9F9F9;
  }
  ::-webkit-scrollbar-thumb{
    background: #EFEFEF;
    border-radius: 10px;
  } */`, "",{"version":3,"sources":["webpack://./src/components/Drawer/Drawer.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;EAC5B;;EAEA;IACE,mBAAmB;EACrB;EACA;IACE,8BAA8B;IAC9B,mBAAmB;EACrB;EACA;IACE,0BAA0B;IAC1B,cAAc;IACd,gBAAgB;IAChB,eAAe;EACjB;AACF;IACI,gBAAgB;IAChB,0BAA0B;IAC1B,eAAe;IACf,cAAc;EAChB;EACA;IACE,0BAA0B;IAC1B,cAAc;IACd,gBAAgB;IAChB,eAAe;EACjB;;EAEA;;;;;;;;;KASG;EACH;IACE,YAAY;IACZ,QAAQ;IACR,SAAS;EACX;EACA;;;;;;;;;KASG","sourcesContent":[".MuiListItemIcon-root {\n    min-width: 33px !important;\n  }\n\n  .css-11biola-MuiDrawer-docked .MuiDrawer-paper {\n    background: #F9F9F9;\n  }\n  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected, .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.Mui-selected {\n    background: #EFEFEF !important;\n    border-radius: 10px;\n  }\n  .MuiTypography-root.MuiListItemText-secondary{\n    font-family: \"Public sans\";\n    color: #000000;\n    font-weight: 400;\n    font-size: 14px;\n  }\n.MuiListItemText-root.primaryText .MuiTypography-root{\n    font-weight: 600;\n    font-family: \"Public sans\";\n    font-size: 18px;\n    color: #000000;\n  }\n  .MuiButtonBase-root.MuiListItemButton-root.MuiListItemButton-gutters.Mui-selected .MuiListItemText-root .MuiTypography-root.MuiListItemText-secondary{\n    font-family: \"Public sans\";\n    color: #000000;\n    font-weight: 600;\n    font-size: 14px;\n  }\n \n  /* .css-1s5q7ck-MuiListItem-root {\n    height: 32px;\n    border-radius: 10px;\n  }\n  .css-1s5q7ck-MuiListItem-root :hover {\n    height: 30px;\n  }\n  .css-36tmq2-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {\n    height: 30px;\n  } */\n  .MuiPopper-root.popper{\n    z-index: 100;\n    top: 10%;\n    left: 20%;\n  }\n  /* ::-webkit-scrollbar{\n    width: 5px;\n  }\n  ::-webkit-scrollbar-track{\n    background: #F9F9F9;\n  }\n  ::-webkit-scrollbar-thumb{\n    background: #EFEFEF;\n    border-radius: 10px;\n  } */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
