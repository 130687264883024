import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";


const Failed = () => { 
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!localStorage.getItem('token')) {
            navigate('/login');
        }
        
    }, []);

    

    console.log('id', id);
    return(
        <Grid>
            <Typography variant="h4" className="subscription-text">Subscription Failed</Typography>
            <Typography variant="h6">Payment failed. Please try again or visit the   <span className="subscriptiontext" onClick={()=>{
                navigate('/subscription')
            }}>Subscription</span> page to verify your payment status.</Typography>
        </Grid>
    )

}

export default Failed;