import React, { useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Paper } from "@mui/material";
import "./CommonTable.css";
import Nodata from "./Nodata";

const CommonTable = ({
  rows,
  columns,
  pageSize = 100,
  onRowClick,
  rowHeight,
  checkboxSelection,
  rowsPerPageOptions,
  key,
  onPageChange,
  localeText,
  showBorder = false,
  rowHighlight = false,
  borderColor = "none",
  backgroundColor = "#EAECF0",
  color = "#000000",
  rowColor = "none",
  rowColorEven = "none",
  rowColorOdd = "none",
  disableSelectionOnClick,
  disableRowSelectionOnClick,
  disableColumnSelector,
  disableColumnResize,
  disableColumnMenu,
  className,
  disableAggregation,
  onSortModelChange,
  onPageSizeChange,
  pagination = true,
  openModalFunction,
  fileName,
  className: classNameProp,
  ...rest
}) => {
  return (
    <div>
      <Paper key={key} className={fileName === "My Usage" && 'table-paper'}>
        <div className={`table-container ${fileName === "My Usage" && 'table-radius'}`}>
          <DataGrid
            sx={{
              "& .MuiDataGrid-row--borderBottom": {
                backgroundColor: `${
                  backgroundColor ? backgroundColor : "transparent"
                } !important`,
                color: `${color ? color : "transparent"}`,
              },
              "& .MuiDataGrid-cell": {
                borderColor: showBorder ? borderColor : "",
                borderWidth: showBorder ? "1px" : "",
              },
              "& .odd-row": {
                backgroundColor: rowColorOdd,
              },
              "& .even-row": {
                backgroundColor: rowHighlight ? rowColor : rowColorEven,
              },
            }}
            rows={rows}
            className={className ? className : ""}
            columns={columns}
            pageSize={pageSize}
            rowHeight={rowHeight ? rowHeight : false}
            checkboxSelection={checkboxSelection ? checkboxSelection : false}
            onRowClick={onRowClick}
            disableSelectionOnClick
            // rowsPerPageOptions = {rowsPerPageOptions ? rowsPerPageOptions : false}
            onPageChange={onPageChange}
            localeText={localeText}
            disableRowSelectionOnClick={disableRowSelectionOnClick}
            disableColumnSelector={disableColumnSelector}
            disableColumnResize={disableColumnResize}
            disableColumnMenu={disableColumnMenu}
            disableAggregation={disableAggregation}
            onSortModelChange={onSortModelChange}
            onPageSizeChange={onPageSizeChange}
            pagination={(pagination = true)}
            {...rest}
          />
          {rows.length === 0 && (
            <div className="no-data-container">
              <Nodata openModalFunction={openModalFunction} fileName={fileName}/>
            </div>
          )}
        </div>
      </Paper>
    </div>
  );
};

export default CommonTable;
