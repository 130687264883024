import { useEffect, useRef, useState } from "react";

const useWebSocket = (models, token) => {
  const [messages, setMessages] = useState([]);
  const socket = useRef(null);
  const isMounted = useRef(true);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    isMounted.current = true;
    const ws = new WebSocket(`${API_URL}/ws/document-status/${models}`);

    ws.onopen = () => {
      console.log(`Socket Connected: ${ws.url}`);
      const payload = { authorization: `Bearer ${token}` };
      ws.send(JSON.stringify(payload));
    };

    ws.onmessage = (event) => {
      if (isMounted.current) {
        const data = JSON.parse(event.data);
        console.log("WebSocket Message:", data);
        setMessages((prevMessages) => [...prevMessages, data]);
      }
    };

    ws.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    ws.onclose = (event) => {
      console.log(`WebSocket Disconnected: ${event.reason || "No reason provided"}`);
    };

    socket.current = ws;

    return () => {
      isMounted.current = false;
      ws.close();
    };
  }, [models, token]);

  return messages;
};

export default useWebSocket;