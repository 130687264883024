import React, { useState, useRef } from "react";
import { Box, TextField, Typography, InputAdornment, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SignupBg from "../../assets/Login-img.png";
import MainLogo from "../../assets/Hawkai-logo.png";
import PasswordStrengthBar from "../Common/PasswordStrength/PasswordStrength";
import CheckIcon from "@mui/icons-material/Check";
import eye from "../../assets/eye.svg";
import viewOff from "../../assets/view-off.svg";
import "./Signup.css";
import ButtonComponent from "../ButtonComponent/Button";
import apiService from "../../service";
import Loader from "../Common/Loader";
import ToastMessage from "../Common/ReactToastifyNotification/Toastify";
import DrawerLogo from "../../assets/DrawerLogo.png";


const Signup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  // Refs for each input field
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const toggleConfirmPasswordVisibility = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // Handle Enter key press
  const handleKeyDown = (e, nextFieldRef) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      if (nextFieldRef && nextFieldRef.current) {
        nextFieldRef.current.focus(); // Move focus to the next field
      }
    }
  };

  // Validation Function
  const validateForm = (data) => {
    const errors = {};
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*:])[a-zA-Z0-9!@#$%^&*:]{8,15}$/;

    if (!data.email.trim()) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(data.email)) {
      errors.email = "Invalid email format";
    }

    if (!data.password) {
      errors.password = "Password is required";
    } else if (!passwordRegex.test(data.password)) {
      errors.password =
        "Password must be 8-15 characters, include at least one number, one letter, and one special character.";
    }

    if (!data.confirmPassword) {
      errors.confirmPassword = "Confirm Password is required";
    } else if (data.password !== data.confirmPassword) {
      errors.confirmPassword = "Passwords do not match";
    }

    return errors;
  };

  const handleBlur = (e) => {
    if (formData.password) {
      e.preventDefault();
      const regex =
        /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*:])[a-zA-Z0-9!@#$%^&*:]{8,15}$/;
      const errors = { ...formErrors };

      if (!regex.test(formData.password)) {
        errors.password =
          "Your password must be more than 8 characters and include at least one number and one lowercase letter.";
      } else {
        errors.password = "";
      }

      setFormErrors(errors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    if (
      formData.name &&
      formData.email &&
      formData.password &&
      formData.confirmPassword
    ) {
      e.preventDefault();
      const errors = validateForm(formData);
      setFormErrors(errors);

      if (Object.keys(errors).length > 0) return;

      const regiPayload = {
        name: formData.name,
        email: formData.email,
        password: formData.password,
      };
      setOpen(true);
      try {
        const regiResponse = await apiService.signupRegister(regiPayload);
        setOpen(false);
        if (regiResponse.status_code === 200) {
          localStorage.setItem("email", regiResponse?.data?.email);
          localStorage.setItem("name", regiResponse?.data?.name);
          localStorage.setItem('tenantId' ,regiResponse?.data?.tenant?.id)
          localStorage.setItem("token", regiResponse?.data?.token);
          localStorage.setItem(
            "refreshToken",
            regiResponse?.data?.refreshToken
          );
          ToastMessage.success(regiResponse.message);
          navigate("/verification");
        }
      } catch (error) {
        console.error("Signup failed:", error);
        ToastMessage.error(`${error.message}`); //toast message
        // if(error.statusCode === 400 && error.message !== "User already exists and please login"){
        //   ToastMessage.error(`${error.message}  and please login`); //toast message
        //   navigate("/login");
        // }
        setOpen(false);
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {open && <Loader open={open} handleClose={handleClose} />}
      <Box className="maincontainer">
        <Box className="image-container">
          {/* <img src={SignupBg} alt="Background" className="background-image" /> */}
          <div className="bg-logo"></div>
        </Box>

        <Box className="right-section">
          <Box className="signup-box">
            <div>
              <img src={DrawerLogo}alt="Logo" className="logo" />
            </div>
            <Typography className="sign-up">Sign Up</Typography>
            <Typography className="Register">Register your account</Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="Full Name"
                name="name"
                variant="outlined"
                margin="normal"
                value={formData.name}
                onChange={handleChange}
                className="textField"
                inputRef={nameRef} // Add ref
                onKeyDown={(e) => handleKeyDown(e, emailRef)} // Handle Enter key
              />

              <TextField
                fullWidth
                required
                name="email"
                label="Email Address"
                variant="outlined"
                margin="normal"
                value={formData.email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
                className="textField"
                inputRef={emailRef} // Add ref
                onKeyDown={(e) => handleKeyDown(e, passwordRef)} // Handle Enter key
              />

              <TextField
                fullWidth
                required
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                margin="normal"
                value={formData.password}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!formErrors.password}
                className="textField"
                helperText={
                  formErrors.password ? (
                    <span
                      className={
                        formErrors.password ===
                        "Your password must be more than 8 characters and include at least one number and one lowercase letter."
                          ? "normal-text"
                          : "error-text"
                      }
                    >
                      {formErrors.password}
                    </span>
                  ) : null
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={togglePasswordVisibility} edge="end">
                        {showPassword ? (
                          <img src={viewOff} alt="viewOff" className="eye-icon" />
                        ) : (
                          <img src={eye} alt="eye-icon" className="eye-icon" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={passwordRef} // Add ref
                onKeyDown={(e) => handleKeyDown(e, confirmPasswordRef)} // Handle Enter key
              />

              {formData.password && <PasswordStrengthBar password={formData.password} />}

              <TextField
                fullWidth
                required
                className="textField"
                name="confirmPassword"
                label="Confirm Password"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                margin="normal"
                value={formData.confirmPassword}
                onChange={handleChange}
                error={!!formErrors.confirmPassword}
                helperText={formErrors.confirmPassword}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={toggleConfirmPasswordVisibility} edge="end">
                        {formData.password &&
                        formData.confirmPassword &&
                        formData.password === formData.confirmPassword ? (
                          <CheckIcon className="checkIcon" />
                        ) : null}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                inputRef={confirmPasswordRef} // Add ref
                onKeyDown={(e) => handleKeyDown(e, null)} // Handle Enter key (no next field)
              />

              <ButtonComponent
                type="submit"
                text="Sign Up"
                className="signup-button"
                onClick={handleSubmit}
              />
            </form>

            <Typography className="terms-text">
              By clicking Sign Up, you agree to our{" "}
              <strong onClick={() => navigate("/terms-of-service")}>
                Terms of Service
              </strong>{" "}
              and{" "}
              <strong onClick={() => navigate("/privacy-policy")}>
                Privacy Policy
              </strong>
            </Typography>

            <Typography cla1sName="signin-text">
              Already have an account?{" "}
              <span className="signin-link" onClick={() => navigate("/login")}>
                Sign In
              </span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Signup;