import React from "react";
import { Box, Typography } from "@mui/material";
import SignupBg from "../../assets/Login-img.png";
import MainLogo from "../../assets/Hawkai-logo.png";
import VerificationIcon from "../../assets/Verification.png";
import "./Signup.css";
import { useNavigate } from "react-router-dom";
import DrawerLogo from "../../assets/DrawerLogo.png";



const VerificationValidation = () => {
  const navigate = useNavigate();
  const emaildetails = localStorage.getItem("email") || "your-email@example.com";

  return (
    <Box className="mains-container">
      {/* Left Image Section */}
      <Box className="image-container">
        {/* <img src={SignupBg} alt="Background" className="background-image" />
         */}
         <div className="bg-logo">

         </div>
      </Box>
      

      {/* Right Verification Section */}
      <Box className="right-container">
     
        <Box className="verification-box">
        <div>
              <img src={DrawerLogo}alt="Logo" className="Dlogo" />
            </div>
          <div>
          <img src={VerificationIcon} alt="Verification Email" className="verification-icon" />
          </div>

          <Typography className="verification-title">Confirm your email</Typography>
          <Typography className="verification-text">
            We’ve sent an email to <span>'{emaildetails}'</span> Check your inbox to activate your account.
          </Typography>

          <Typography className="resend-text">
            {/* Didn’t receive an email? <span className="resend-link">Send again</span> */}
          </Typography>

          {/* <Typography className="signup-text">
            Take me back to <span className="signup-link" onClick={()=>navigate('/signup')}>Sign Up</span>
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default VerificationValidation;
