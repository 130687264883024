// src/services/apiService.js
import axios from 'axios';

const HttpClient = axios.create();

const API_URL = `${process.env.REACT_APP_API_URL}/api`;
const API_VERSION = process.env.REACT_APP_API_VERSION;
console.log(API_URL)



// Create Axios instance
const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true, // Sends cookies with each request
});


// Request interceptor
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('token');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    config.headers['Content-Type'] = 'application/json';
    console.log('Axios Request Headers:', config.headers); 
    return config;
  },
  (error) => Promise.reject(error)
);


const callApiWithRetry = async (url, method,formData) => {
  console.log('formData',formData)
  const localStorageData = localStorage.getItem('token');
  if (!localStorageData) {
    window.location.href = '/login';
  }
  const options = {
    method: method,
    headers: {
      Authorization: `Bearer ${localStorageData}`,
    },
    data: method === "POST" && formData ? formData : undefined,
  };
  try {
    const response = await HttpClient(url,options);
    return response; // Return the response if successful
  } catch (error) {
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      try {
        // Call refresh token API
        const refreshToken = localStorage.getItem("refreshToken");
        const refreshResponse = await axios.post(`${API_URL}/${API_VERSION}/user/refresh-token`, {
          withCredentials: true,
          access_token: localStorage.getItem("token"),
          refresh_token: refreshToken,
        });
        console.log(refreshResponse, "refreshResponse")
        
        // Update the access token in local storage
        const newAccessToken = refreshResponse.data?.data?.token;
        localStorage.setItem("token", newAccessToken);

        // Retry the original request with the new token
        const retryOptions = {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${newAccessToken}`,
          },
        };
        const retryResponse = await HttpClient(url, retryOptions);
        return retryResponse;
      } catch (refreshError) {
        console.error("Failed to refresh token:", refreshError);
        if(refreshError.response.data.statusCode === 403 || refreshError.response.status === 500){
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          window.location.href = "/login";
        }
        // throw refreshError; // Re-throw error for further handling
      }
    } else {
      throw error; // Re-throw error for further handling
    }
  }
};






// const getDataById = async (id) => {
//   try {
//     const response = await HttpClient.get(`${API_URL}/AIController/${id}`, {
//       headers: { 'Content-Type': 'application/json' },
//     });
//     return response.data.data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// const getData = async (userId) => {
//   try {
//     const response = await HttpClient.get(`${API_URL}/AIController/all/${userId}`, {
//       headers: { 'Content-Type': 'application/json' },
//     });
//     return response.data.data;
//   } catch (error) {
//     console.error('Error fetching data:', error);
//     throw error;
//   }
// };

// const analyzeData = async (formData, userId) => {
//   try {
//     const response = await HttpClient.post(`${API_URL}/v1/analyze`, formData, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//       params: {
//         "user_id": userId
//       }
//     });
//     return response;
//   } catch (error) {
//     console.error('Error posting data:', error);
//     throw error;
//   }
// };

const analyseInvoice = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/invoice/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const analyseGenDocument = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/general-document/scan`, 'POST', formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const analyseCriminalCertificate = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/myai/async/criminal-document/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const login = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/${API_VERSION}/user/login`, data, {
      headers: {
        'Content-Type': 'application/json',
      },

    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error.response.data;
  }
};

//Criminal Certificates
const getCriminalCertificate = async () => {
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/myai/criminal-documents`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getCriminalCertificateDataById = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/myai/criminal-document/${id}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getInvoice = async () => {
  // const headerToken = localStorage.getItem("token")
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/invoices`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getInvoiceDataById = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/invoice/${id}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getGenDocuments = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/general-documents`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getGenDocumentDataById = async (id) => {
  // const headerToken = localStorage.getItem("token")
 try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/general-document/${id}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

// Aadhar card section
const getAadhar = async () => {
  // const headerToken = localStorage.getItem("token")

 try{
  const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/aadhars`, "GET");
  return response.data;
 }catch (error) {
  console.error('Error fetching data:', error);
  throw error;
}
}

const getAadharDataById = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try{
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/aadhar/${id}`, "GET");
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const uploadFileAadhar = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/aadhar/scan`, "POST", formData);
    return response.data;
  }
  catch (error) { 
    console.error('Error posting data:', error);
    throw error;
  }
}

// Pan card section
const getPan = async () => {
  // const headerToken = localStorage.getItem("token")
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/pans`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getpandataById = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/pan/${id}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const analysePan = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/pan/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
//bank cheque section
const getbankcheque = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/bank-cheques`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getbankchequedataById = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/bank-cheque/${id}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const analysebankcheque = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/bank-cheque/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
//eway Bill Section
const getewaybill = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/eway-bills`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getewaybilldataById = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/eway-bill/${id}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const analyseewaybill = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/eway-bill/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};

//GST section
const getGST = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/gst-certifications` , "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getGstDataId = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try{
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/gst-certification/${id}`, "GET");
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const analyseGstScan = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/gst-certification/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};
const getreceipt = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/receipts`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};
const getreceiptDataId = async (id) => {
  // const headerToken = localStorage.getItem("token")
  try{
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/receipt/${id}`, "GET");
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const analysereceipt = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/receipt/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
};


//Business card section
const getBusinessCard = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/business-cards`, "GET");
    console.log("responseAPI", response)
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

const getBusinessCardId = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try{
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/business-card/${id}`, "GET");
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const analyseBusinessCard = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/business-card/scan`, "POST", formData);
    console.log(response, "APISCAN")
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}

//id documents here
const getIDDocument = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/id-documents`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const getIDDocumentDataID = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try{
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/id-document/${id}`, "GET");
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

const analyseIdDocument = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/id-document/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}
//passbook section
const getpassbook = async () => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/bank-passbooks`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getPassBookBYID = async (id) => {
  // const headerToken = localStorage.getItem("token")

  try{
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/bank-passbook/${id}`, "GET");
    return response.data;
  }
  catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const analysePassbook = async (formData) => {
  // const headerToken = localStorage.getItem("token")

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/async/bank-passbook/scan`, "POST", formData);
    return response;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error;
  }
}
//common sort api
const getCommonSortAPI = async (sortField, sortOrder, API_NAME, page, limit) => {
  // const localStorageData = localStorage.getItem('token');
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/${API_NAME}?sort_by=${sortField}&order=${sortOrder}?page=${page}&limit=${limit}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getCommonLimits = async (page, API_NAME, limit) => {
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/${API_NAME}?page=${page}&limit=${limit}`, "GET");
    return response.data
  } catch (error) {
    console.error("Failed to fetch invoice:", error);
  }
  // try {
  //   const response = await HttpClient.get(`${API_URL}/${API_VERSION}/${API_NAME}?page=${page}&limit=${limit}`);
  //   return response.data;
  // } catch (error) {
  //   console.error('Error fetching data:', error);
  //   throw error;
  // }
}

//MI ai common limits
const myAiCommonLimits = async (page, API_NAME, limit) => {
  

  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/myai/${API_NAME}?page=${page}&limit=${limit}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
const getAISortAPI = async (sortField, sortOrder, API_NAME, page, limit) => {
  // const localStorageData = localStorage.getItem('token');
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/myai/${API_NAME}?sort_by=${sortField}&order=${sortOrder}?page=${page}&limit=${limit}`, "GET");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}

// Signup API
const signupRegister = async (data) => {
  try {
    const response = await HttpClient.post(`${API_URL}/${API_VERSION}/user/register`, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error.response.data;
  }
}

// Verification Id api
const verificationValid = async(verificationId) =>{
  try{
    const response = await HttpClient.post(`${API_URL}/${API_VERSION}/user/verify-email`, {verificationId:verificationId}, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error.response.data;
  }
}

const forgotpassword = async(data) =>{
  try{
    const forgotPasswordResponse =  await HttpClient.post(`${API_URL}/${API_VERSION}/user/forgot-password`, data);
    return forgotPasswordResponse.data
  }catch (error){
    console.log("Error fetching data:", error)
    throw error;
  }
}
const resetPasswordFun = async(data) =>{
  try{
    const forgotPasswordResponse =  await HttpClient.post(`${API_URL}/${API_VERSION}/user/reset-password`, data);
    return forgotPasswordResponse.data
  }catch (error){
    console.log("Error fetching data:", error)
    throw error;
  }
}

//change-password
const changePassword = async (data) => {
  console.log("changePassword",data)
  try {
    const response =  await callApiWithRetry(`${API_URL}/${API_VERSION}/user/change-password`, "POST", data);
    return response.data;
  } catch (error) {
    console.error('Error posting data:', error);
    throw error.response.data;
  }
}

//api-Key generate
const apiKeyGenerate = async () => {
  try {
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/user/apikey`, "POST");
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
}
// Logout api
 const logoutFunc = async () => {
    try {
      const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/user/logout`, "POST")
      return response.data;
    } catch (error) {
      console.error('Error posting data:', error);
      throw error.response.data;
    }
  }
  const updateDataAvailable = (prevData, messages) => {
    if (!messages || messages.length === 0) return prevData;
  
    const latestMessage = messages[messages.length - 1]; // Get the latest message
  
    const updatedData = prevData
      .map((row) =>
        row.id === latestMessage.id
          ? { ...row, extracted_data: latestMessage.extracted_data }
          : row
      )
      .filter((row) => row.id !== latestMessage.id);
  
    // Insert the latest message at the beginning
    return [{ ...latestMessage }, ...updatedData];
  };
  const deleteDocument = async(type,id)=>{
    try{
      const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/${type}/${id}`, 'DELETE');
      return response.data;
    }
    catch(error){
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  const getProductList = async () => {
    try {
      const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/stripeProducts/productList`, "GET");
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  const createCheckoutSession = async (data) => {
    try {
      const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/stripeProducts/create-checkout-session`, "POST", data);
      return response.data;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  // userCreditpoints
const userCreditPoints = async (userId) => {
  try {
    const payload = {
      "user_id": userId.trim()
    }
    console.log("payload", typeof payload)
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/stripeProducts/getCreditPoints`, 'POST', JSON.stringify(payload));
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};

//userDetails
const userUsageDetails = async() =>{
  try{
    const response = await callApiWithRetry(`${API_URL}/${API_VERSION}/user/usage-details`, "GET");
    return response.data;
  }catch(error){
    console.error('Error fetching data:', error);
    throw error;
  }
}

  
  

const apiService = {
  // getDataById,
  // getData,
  // analyzeData,
  login,
  getInvoice,
  getInvoiceDataById,
  analyseInvoice,
  analyseGenDocument,
  getGenDocuments,
  getGenDocumentDataById,
  getCriminalCertificate,
  getCriminalCertificateDataById,
  analyseCriminalCertificate,
  getPan,
  getpandataById,
  analysePan,
  getAadhar,
  uploadFileAadhar,
  getAadharDataById,
  getbankcheque,
  getbankchequedataById,
  analysebankcheque,
  getewaybill,
  getewaybilldataById,
  analyseewaybill,
  getGST,
  getGstDataId,
  analyseGstScan,
  getBusinessCard,
  getBusinessCardId,
  analyseBusinessCard,
  getIDDocument,
  getIDDocumentDataID,
  analyseIdDocument,
  getreceipt,
  getreceiptDataId,
  analysereceipt,
  getpassbook,
  getPassBookBYID,
  analysePassbook,
  getCommonSortAPI,
  getCommonLimits,
  myAiCommonLimits,
  getAISortAPI,
  signupRegister,
  verificationValid,
  forgotpassword,
  resetPasswordFun,
  changePassword,
  apiKeyGenerate,
  logoutFunc,
  updateDataAvailable,
  getProductList,
  deleteDocument,
  createCheckoutSession,
  userCreditPoints,
  userUsageDetails
};

export default apiService;