import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/main-logo.svg";
import login from "../../assets/Login-img.png";
import { TextField, Typography, Box, IconButton, InputAdornment } from "@mui/material";
import ButtonComponent from "../ButtonComponent/Button";
import "./ResetPassword.css";
import apiService from "../../service";
import eye from "../../assets/eye.svg";
import viewOff from "../../assets/view-off.svg";
import DrawerLogo from "../../assets/DrawerLogo.png";

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("weak");
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const location = useLocation()
  const routerPath = location.pathname
  const changePasswordId = routerPath.split("/").pop();

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value }); 
    validateForm(name, value); 
  
  
    const { password } = formData; 
  
    if (!password) {
      setPasswordError("Password is required.");
      return;
    }
   
  };


  const validateForm = (name, value) => {
    let errors = { ...formErrors };
    
    if (name === "password") {
      const passwordRegex = /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,15}$/;
      
      if (!value) {
          errors.password = "Password is required";
      } else if (value.length < 8 || value.length > 15) {
          errors.password = "Password must be 8 to 15 characters ";
      } else if (!passwordRegex.test(value)) {
          errors.password = "Password must contain at least one number and one special character";
      } else {
          errors.password = "";
          updatePasswordStrength(value);
      }
  }

    if (name === "confirmPassword") {
      if (!value) {
        errors.confirmPassword = "Please confirm your password";
      } else if (value !== formData.password) {
        errors.confirmPassword = "Passwords do not match";
      } else {
        errors.confirmPassword = "";
      }
    }

    setFormErrors(errors);
  };

  const updatePasswordStrength = (password) => {
    if (password.length >= 12) {
      setPasswordStrength("strong");
    } else if (password.length >= 8) {
      setPasswordStrength("medium");
    } else {
      setPasswordStrength("weak");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    validateForm("password", formData.password);
    validateForm("confirmPassword", formData.confirmPassword);

    const ResetPasswordPayLoad = {
      change_password_id: changePasswordId,
      new_password: formData.password,
    };
  
    try {
      const apiForgotRes = await apiService.resetPasswordFun(ResetPasswordPayLoad);
  
      if (apiForgotRes.status_code === 200) {
          navigate(`/login`);
      }
      } catch (error) {
      console.log(error);
      setPasswordError("An error occurred. Please try again.");
    }
  };

  return (
    <div className="main-container">
      <Box className="imagecontainer">
        {/* <img src={login} alt="Background" /> */}
        <div className="bg-logo">

        </div>
      </Box>
      <Box className="Rightcontainer">
        <Box sx={{ maxWidth: 350, width: "100%" }}>
          <Box className="righttopconatiner">
            <div>
              <img src={DrawerLogo} alt="Logo" className="Toplogo" />
            </div>
            <Typography
              className="Backtologin"
            >            </Typography>
            <Typography className="sign-in">Reset Your Password?</Typography>
            <Typography variant="Subtitle-1">
            Please set a new password for your account.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              label="New Password*"
              variant="outlined"
              name="password"
              type={showPassword ? "text" : "password"}
              value={formData.password}
              onChange={handleChange}
              error={Boolean(formErrors.password)}
              helperText={formErrors.password}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <IconButton
                  onClick={togglePasswordVisibility}
                  edge="end"
                  className="visibility-icon"
                >
                  {showPassword ? (
                    <img src={viewOff} alt="viewOff" className="eye-icon" />
                  ) : (
                    <img src={eye} alt="eye-icon" className="eye-icon" />
                  )}
                </IconButton>
                ),
              }}
            />
            <Box className="passwordStrength">
              {[...Array(3)].map((_, index) => (
                <Box
                  key={index}
                  className={`passwordBar ${
                    passwordStrength === "strong"
                      ? "strong"
                      : passwordStrength === "medium" && index <= 1
                      ? "medium"
                      : passwordStrength === "weak" && index === 0
                      ? "weak"
                      : ""
                  }`}
                />
              ))}
            </Box>
            <TextField
              label="Confirm New Password*"
              variant="outlined"
              name="confirmPassword"
              type={showConfirmPassword ? "text" : "password"}
              value={formData.confirmPassword}
              onChange={handleChange}
              error={Boolean(formErrors.confirmPassword)}
              helperText={formErrors.confirmPassword}
              fullWidth
              margin="normal"
              InputProps={{
                endAdornment: (
                  <IconButton
                  onClick={toggleConfirmPasswordVisibility}
                  edge="end"
                  className="visibility-icon"
                >
                  {showConfirmPassword ? (
                    <img src={viewOff} alt="viewOff" className="eye-icon" />
                  ) : (
                    <img src={eye} alt="eye-icon" className="eye-icon" />
                  )}
                </IconButton>
                ),
              }}
            />
            <ButtonComponent className="button" type="submit" text="Submit" />
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default ResetPassword;
