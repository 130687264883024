import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Logo from "../../assets/main-logo.svg";
import login from "../../assets/Login-img.png";
import { TextField, Typography, Box } from "@mui/material";
import ButtonComponent from "../ButtonComponent/Button";
import "./ForgotPassword.css";
import apiService from "../../service";
import ToastMessage from "../Common/ReactToastifyNotification/Toastify";
import MainLogo from "../../assets/Hawkai-logo.png";
import DrawerLogo from "../../assets/DrawerLogo.png";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const navigate = useNavigate();
  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
    setEmailError(validateEmail(e.target.value) ? "" : "Invalid email");
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if email is provided
    if (!email) {
      setEmailError("Email is required");
      return;
    }

    if (!validateEmail(email)) {
      setEmailError("Invalid email");
      return;
    }

    setEmailError("");

    const forgotPasswordPayLoad = {
      email: email,
    };

    try {
      const apiForgotRes = await apiService.forgotpassword(forgotPasswordPayLoad);

      if (apiForgotRes.status === 'true') {
        // const resetpasswordId = apiForgotRes.data?.changePasswordId;
        ToastMessage.success(apiForgotRes.message)
      } else {
        setEmailError(apiForgotRes.message );
      }
    } catch (error) {
      console.log(error);
      setEmailError("An error occurred. Please try again.");
    }
  };
  

  return (
    <div className="main-container">
      <Box className="imagecontainer">
        {/* <img src={login} alt="Background" /> */}
        <div className="bg-logo">

        </div>
      </Box>
      <Box className="Rightcontainer">
        <Box sx={{ maxWidth: 350, width: "100%" }}>
          <Box className="righttopconatiner">
            <div>
              <img src={DrawerLogo} alt="Logo" className="Toplogo" />
            </div>
            <Typography
              className="Backtologin"
              onClick={() => navigate("/login")}
            >
              <ArrowBackIosNewIcon className="arrowicon" onClick={() => navigate("/login")} /> Back to Sign In
            </Typography>
            <Typography className="forgot">Forgot Your Password?</Typography>
            <Typography variant="Subtitle-1">
              Enter your email address and we’ll send you a link to reset your
              password.
            </Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Email Address*"
              variant="outlined"
              className="inputfield"
              name="email"
              type="email"
              value={email}
              onChange={handleChange}
              error={Boolean(emailError)}
              helperText={emailError}
              fullWidth
            />
            <ButtonComponent  className="button" type="submit" text="continue" />
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default ForgotPassword;
