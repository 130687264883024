import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar from "../Snackbar/Snackbar";
import MainLogo from "../../assets/Hawkai-logo.png";
import DrawerLogo from "../../assets/DrawerLogo.png";
import login from "../../assets/Login-img.png";
import eye from "../../assets/eye.svg";
import viewOff from "../../assets/view-off.svg";
import {
  Container,
  TextField,
  Button,
  Typography,
  Box,
  Link,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import apiService from "../../service";
import "./Login.css";
import ButtonComponent from "../ButtonComponent/Button";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarVariant, setSnackbarVariant] = useState("success");
  
  const validateForm = () => {
    let isValid = true;
    let errors = { email: "", password: "" }; // Initialize an error object
  
    if (!email) {
      errors.email = "Email is required";
      isValid = false;
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      errors.email = "Invalid email format";
      isValid = false;
    }
  
    if (!password) {
      errors.password = "Password is required";
      isValid = false;
    } else if (password.length < 6) {
      errors.password = "Password must be at least 6 characters";
      isValid = false;
    }
  
    setFormErrors(errors); // Update the error state
    return isValid;
  };
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    setShowSnackbar(false);
  
    if (!validateForm()) return;
  
    try {
      const obj = { email, password, withCredentials: true };
      const response = await apiService.login(obj);
  
      if (response.status) {
        setSnackbarMessage(response.message);
        setSnackbarVariant("success");
        setShowSnackbar(true);
  
        localStorage.setItem("userId", response?.data?.user_id);
        localStorage.setItem("name", response?.data?.name);
        localStorage.setItem("access", response?.data?.tenant?.access_list);
        localStorage.setItem("email", response?.data?.email);
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem('tenantId' ,response?.data?.tenant?.id)
        localStorage.setItem('userType', response?.data?.tenant?.consumer_type)
        localStorage.setItem('organizationName', response?.data?.tenant?.organization_name)
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
        localStorage.setItem("api_key", response?.data?.api_key || '');
        if(response?.data?.tenant?.access_list?.includes('IDCriminalDocument')){
          navigate('/my-ai/criminal-document')
        }else{
          navigate('/doc-ai/invoice')
        }

      } else {
        if (response.statusCode === 401 || response.statusCode === 403 || response.statusCode === 500) {
          setFormErrors({
            email: response.message,
            password: response.message,
          });
        } else {
          setSnackbarMessage(response.message);
          setSnackbarVariant("error");
          setShowSnackbar(true);
        }
      }
    } catch (error) {
      if (error?.statusCode === 401 || error?.statusCode === 403 || error?.statusCode === 500) {
        setFormErrors({
          email: error.message,
          password: error.message,
        });
      } else {
        setSnackbarMessage(error.message);
        setSnackbarVariant("error");
        setShowSnackbar(true);
      }
    }
  };
  

  useEffect(() => {
    const loggedInUser = localStorage.getItem("user");
    if (loggedInUser) {
      navigate("/custom-document-validation"); // Redirect to dashboard if already logged in
    }
  }, [navigate]);

  return (
    <div className="main-container">
      <Box className="imagecontainer">
       <div className="bglogo">

       </div>
      </Box>

      <Box className="rightcontainer">
        <Box sx={{ maxWidth: 350, width: "100%" }}>
          <Box className="righttopconatiner">
            <div>
              <img src={DrawerLogo}alt="Logo" className="logo" />
            </div>
             <Typography className="Sign-in">Sign In</Typography>
            <Typography variant="subtitle-1">Please login to continue to your account.</Typography>
          </Box>
          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Email Address"
              type="email"
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!formErrors.email}
              helperText={formErrors.email}
            />
            <TextField
              variant="outlined"
              required
              fullWidth
              label="Password"
              type={showPassword ? "text" : "password"}
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={!!formErrors.password}
              helperText={formErrors.password}
              InputProps={{
                endAdornment: (
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                    className="visibility-icon"
                  >
                    {showPassword ? (
                      <img src={viewOff} alt="viewOff" className="eye-icon" />
                    ) : (
                      <img src={eye} alt="eye-icon" className="eye-icon" />
                    )}
                  </IconButton>
                ),
              }}
            />
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 2 }}>
              <FormControlLabel control={<Checkbox size="small" />} label="Remember Me" />
              <Link className="forgotlink" onClick={() => navigate("/forgotpassword")}>Forgot Password?</Link>
            </Box>
            <ButtonComponent text="Sign In" className="login-button" type="submit" onClick={handleSubmit}/>
            <Typography className="subtitle2">
              By clicking Sign In, you agree to our <strong onClick={() => navigate("/terms-of-service")}>Terms of Service</strong> and <strong onClick={() => navigate("/privacy-policy")}>Privacy Policy</strong>
            </Typography>
            <Typography align="center">
              Don&apos;t have an account? <Link className="signup" onClick={() => navigate("/signup")}>Sign Up</Link>
            </Typography>
          </form>
        </Box>
      </Box>
    </div>
  );
};

export default Login;
