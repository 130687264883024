import React, { useState, useRef } from 'react'
import { Grid, Typography } from '@mui/material'
import NodataImage from "../../assets/NoData.png"
import ButtonComponent from '../ButtonComponent/Button';
import Plus from "../../assets/upload-plus.png";

function Nodata({openModalFunction, fileName}) {
    return (
        <Grid container justifyContent="center" alignItems="center" style={{ height: "100%" }}>
                <Grid item style={{ textAlign: 'center' }}>
                <img src={NodataImage} alt="No Data" className='nodataImage' />
                <Typography className='nodata-title'>No Data Available</Typography>
                <Typography className='nodata-description'>{`We haven’t found any document. Please upload ${fileName}`}</Typography>
                {fileName !== "My Usage" && (
                <div className='button-data'>
                <ButtonComponent
                    className="analyse-uploads"
                    icon={Plus}
                    iconAlt="Upload"
                    iconHeight={15}
                    text="Upload"
                    onClick={openModalFunction}
                />
                </div>
                )}

            </Grid>
        </Grid>
    )
}

export default Nodata