// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toastifyContainer.Toastify__toast-theme--colored.Toastify__toast--error{
    color: #000000;
    background-color: transparent;
    font-size: 1rem;
    font-family: 'SF Pro Text';
    font-weight: 400;
}
.toastifyContainer .Toastify__close-button.Toastify__close-button--colored{
    color: #000000;
}
.toastifyContainer .Toastify__toast-icon{
    display: none;
}
.MuiBox-root.toast-container{
    display: flex;
    align-items: center;
}
.toast-icon{
    width: 2rem;
    height: 2rem;
    margin-right: 10px;
}
.Toastify__toast-container{
    width: 23%;
}
.MuiTypography-root.ToastTitle{
    font-size: 1rem;
    font-family: 'Public Sans';
    font-weight: 600;
    color: #0B0B0B;
}`, "",{"version":3,"sources":["webpack://./src/components/Common/ReactToastifyNotification/Toastify.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,6BAA6B;IAC7B,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;AACpB;AACA;IACI,cAAc;AAClB;AACA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,UAAU;AACd;AACA;IACI,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;IAChB,cAAc;AAClB","sourcesContent":[".toastifyContainer.Toastify__toast-theme--colored.Toastify__toast--error{\n    color: #000000;\n    background-color: transparent;\n    font-size: 1rem;\n    font-family: 'SF Pro Text';\n    font-weight: 400;\n}\n.toastifyContainer .Toastify__close-button.Toastify__close-button--colored{\n    color: #000000;\n}\n.toastifyContainer .Toastify__toast-icon{\n    display: none;\n}\n.MuiBox-root.toast-container{\n    display: flex;\n    align-items: center;\n}\n.toast-icon{\n    width: 2rem;\n    height: 2rem;\n    margin-right: 10px;\n}\n.Toastify__toast-container{\n    width: 23%;\n}\n.MuiTypography-root.ToastTitle{\n    font-size: 1rem;\n    font-family: 'Public Sans';\n    font-weight: 600;\n    color: #0B0B0B;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
