// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-container-model{
 display: flex;
 justify-content: end;
 margin-bottom: 10px;
}
.MuiDataGrid-row--borderBottom {
    background-color: #EAECF0;
  }`, "",{"version":3,"sources":["webpack://./src/components/Pancard/Pan.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,oBAAoB;CACpB,mBAAmB;AACpB;AACA;IACI,yBAAyB;EAC3B","sourcesContent":[".button-container-model{\n display: flex;\n justify-content: end;\n margin-bottom: 10px;\n}\n.MuiDataGrid-row--borderBottom {\n    background-color: #EAECF0;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
