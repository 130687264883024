import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import DrawerComponent from "./components/Drawer/Drawer";
import MainComponent from "./components/MainComponent/MainComponent";
import Login from "./components/Login/Login";
import VerificationValidationPage from './components/Signup/Verification'
import VerificationIdPage from "./components/Verification/VerificationId";
import './App.css'
import Signup from "./components/Signup/Signup";
import { ToastContainer } from "react-toastify";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import Policy from "./components/HawkaiTerms/PrivatePolicy";
import TermsAndConditions from "./components/HawkaiTerms/Terms";
import { CreditPointsProvider } from "./creditPoints";
import PageTitle from "./seoTitle";

function MainContent() {
  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const show = location.pathname==='/verification' ||  location.pathname === '/login'|| location.pathname==='/signup'|| location.pathname==='/privacy-policy'|| location.pathname==='/forgotpassword'|| location.pathname==='/terms-of-service'  || location.pathname.startsWith("/reset-password/")  ? false : true;


  const handleDrawerToggle = () => {
    setOpen(!open);
  };
  return (
    <>
    <PageTitle />
      {show && (
        <Box sx={{ display: "flex", width: "100%" }}>
          <CreditPointsProvider>
          <CssBaseline />
          <DrawerComponent open={open} handleDrawerToggle={handleDrawerToggle} />
          <MainComponent open={open} />
          </CreditPointsProvider>
        </Box>
      )}
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/verification" element={<VerificationValidationPage/>} />
        <Route path="/signup" element={<Signup />} />
        <Route path ="/forgotpassword" element={<ForgotPassword/>} />
        <Route path ="/reset-password/:id" element={<ResetPassword/>} />
        <Route path="/terms-of-service" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<Policy />} />
        {/* Add more routes here if needed */}
      </Routes>
    </>
  );
}

export default function PersistentDrawerLeft() {
  return (
    <>
      <ToastContainer />
      <MainContent />
    </>
  );
}
