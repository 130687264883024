import React from "react";

const ButtonComponent = ({
  onClick,
  buttonRef,
  icon,
  iconAlt,
  iconHeight,
  text,
  className,
  style,
}) => {
  return (
    <button ref={buttonRef} onClick={onClick} className={className} style={style}>
      {icon && (
        <img
          src={icon}
          alt={iconAlt}
          height={iconHeight}
          style={{ marginRight: 5 }}
        />
      )}
      {text}
    </button>
  );
};

export default ButtonComponent;
