// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.strength-bar {
    display: flex;
    gap: 5px;
    margin-top: 8px;
    align-items: center;
  }
  
  .strength-segment {
    width: 30px;
    height: 8px;
    border-radius: 4px;
    background-color: #ddd;
    transition: background-color 0.3s ease-in-out;
  }
  
  .strength-label {
    font-size: 14px;
    font-weight: bold;
    text-align: left;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Common/PasswordStrength/PasswordStrength.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,QAAQ;IACR,eAAe;IACf,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,WAAW;IACX,kBAAkB;IAClB,sBAAsB;IACtB,6CAA6C;EAC/C;;EAEA;IACE,eAAe;IACf,iBAAiB;IACjB,gBAAgB;EAClB","sourcesContent":[".strength-bar {\n    display: flex;\n    gap: 5px;\n    margin-top: 8px;\n    align-items: center;\n  }\n  \n  .strength-segment {\n    width: 30px;\n    height: 8px;\n    border-radius: 4px;\n    background-color: #ddd;\n    transition: background-color 0.3s ease-in-out;\n  }\n  \n  .strength-label {\n    font-size: 14px;\n    font-weight: bold;\n    text-align: left;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
