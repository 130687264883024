import React, { useRef, useState, useEffect } from "react";
import { Stage, Layer, Image, Rect } from "react-konva";
import NavigationComponent from "../NavigationComponent/NavigationComponent";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import "../PreviewComponent/PreviewComponent.css";
import './Preview.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const colors = [
  "red",
  "blue",
  "green",
  "yellow",
  "purple",
  "orange",
  "pink",
  "cyan",
  "magenta",
];

const DataGridComponent = ({ rows, headers }) => {
  return (
    <table className="data-grid">
      <thead>
        <tr>
          {headers.map((header, index) => (
            <th key={index}>{header.content}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((cell, cellIndex) => (
              <td key={cellIndex}>{cell ? cell.content : ""}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const useImage = (src) => {
  const [image, setImage] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (src) {
      const img = new window.Image();
      img.src = src;
      img.onload = () => setImage(img);
      img.onerror = () => setError("Failed to load image");
    }
  }, [src]);

  return [image, error];
};

const PreviewComponent = ({  breadCrumbNames, dataAvailable }) => {
  const [rectangles, setRectangles] = useState([]);
  const [hoveredRectIndex, setHoveredRectIndex] = useState(null);
  const [imageBlobUrls, setImageBlobUrls] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [image, error] = useImage(imageBlobUrls[currentIndex]);
  const stageRef = useRef(null);
  const [breadCrumb, setBreadCrumb] = useState([]);
  const [fileName, setFileName] = useState("");
  const [maxIndex, setMaxIndex] = useState();
  const panelRef = useRef(null);
  const stageWidth = 830;
  const stageHeight = image ? (image.height * stageWidth) / image.width : window.innerHeight;

  const convertToPixels = (polygon, scaleFactor) => {
    return polygon.map((coord) => coord * scaleFactor);
  };

  const calculateBoundingBox = (polygon) => {
    const xCoords = polygon.filter((_, index) => index % 2 === 0);
    const yCoords = polygon.filter((_, index) => index % 2 !== 0);

    const xMin = Math.min(...xCoords);
    const xMax = Math.max(...xCoords);
    const yMin = Math.min(...yCoords);
    const yMax = Math.max(...yCoords);

    const width = xMax - xMin;
    const height = yMax - yMin;

    return {
      x: xMin,
      y: yMin,
      width,
      height,
    };
  };

  useEffect(() => {
    if (dataAvailable) {
        const scaleFactor = 100;
        const data = [];

        // Safely set file name and breadcrumb
        setFileName(dataAvailable.file_name || "Unknown File");
        const breadCrumbArray = (breadCrumbNames || []).map((item) => ({
            name: item.name || "",
            color: "#767676",
            underline: "none",
            href: item.href || "",
        }));
        setBreadCrumb(breadCrumbArray);
        const sourceImages = dataAvailable.source_images || [];
        setMaxIndex(sourceImages.length);

        const extractedData = dataAvailable.extracted_data || {};
        for (const key in extractedData) {
            if (extractedData.hasOwnProperty(key)) {
                const boundary = extractedData[key];
                console.log("Boundary:", boundary);

                if (
                    (boundary?.BoundingRegions?.length > 0) ||
                    (boundary?.Boundary?.length > 0)
                ) {
                    const region = boundary.BoundingRegions
                        ? boundary.BoundingRegions[0]
                        : boundary.Boundary[0]; // Access the first region

                    if (region?.PageNumber === currentIndex + 1) {
                        const polygon = Array.isArray(region.Polygon?.[0])
                            ? region.Polygon.flat()
                            : region.Polygon;

                        if (polygon) {

                            const boundingBoxPDF = calculateBoundingBox(polygon);
                            const boundingBoxInPixels = convertToPixels(
                                [
                                    boundingBoxPDF.x,
                                    boundingBoxPDF.y,
                                    boundingBoxPDF.width,
                                    boundingBoxPDF.height,
                                ],
                                scaleFactor
                            );

                            const increasedWidth = boundingBoxInPixels[2] + 25;
                            const increasedHeight = boundingBoxInPixels[3] + 5;

                            if (boundary["Content"] || boundary.Content) {
                                data.push({
                                    [key]: boundary.ValueString || boundary.Content,
                                    x: boundingBoxInPixels[0],
                                    y: boundingBoxInPixels[1],
                                    width: increasedWidth,
                                    height: increasedHeight,
                                    color: colors[data.length % colors.length],
                                });
                            }
                        }
                    }
                }
            }
        }
        setRectangles(data);
        setImageBlobUrls(sourceImages);
    }
}, [dataAvailable, currentIndex]);


  const handleMouseEnterRect = (index) => {
    setHoveredRectIndex(index);
  };

  const handleMouseLeaveRect = () => {
    setHoveredRectIndex(null);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, maxIndex - 1));
  };

  return (
    <>
      <CustomBreadCrumbs array={breadCrumb} />
      <div className="general-doc-validation">
        <div className="konva-container">
          <div className="konva-scroll">
            {imageBlobUrls.length > 1 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <NavigationComponent
                  onPrevious={handlePrevious}
                  onNext={handleNext}
                  isPreviousDisabled={currentIndex === 0}
                  isNextDisabled={currentIndex === maxIndex - 1}
                />
                <p style={{ margin: 0 }}>
                  {currentIndex + 1} of {maxIndex}
                </p>
              </div>
            )}
            <div className="konva-scroll-inner">
              <Stage
                className="konva-stage"
                width={stageWidth}
                height={stageHeight}
                ref={stageRef}
              >
                <Layer>
                  {image && (
                    <Image
                      image={image}
                      width={stageWidth}
                      height={stageHeight}
                    />
                  )}
                  {rectangles.map((rect, index) => (
                    <Rect
                      key={index}
                      x={rect.x}
                      y={rect.y}
                      width={rect.width}
                      height={rect.height}
                      fill=""
                      stroke={hoveredRectIndex === index ? "red" : rect.color}
                      strokeWidth={2}
                      draggable
                      onMouseEnter={() => handleMouseEnterRect(index)}
                      onMouseLeave={handleMouseLeaveRect}
                      onDragEnd={(e) => {
                        const newRectangles = [...rectangles];
                        newRectangles[index] = {
                          ...rect,
                          x: e.target.x(),
                          y: e.target.y(),
                        };
                        setRectangles(newRectangles);
                      }}
                      onTransformEnd={(e) => {
                        const node = e.target;
                        const scaleX = node.scaleX();
                        const scaleY = node.scaleY();

                        node.scaleX(1);
                        node.scaleY(1);
                        const newRectangles = [...rectangles];
                        newRectangles[index] = {
                          ...rect,
                          x: node.x(),
                          y: node.y(),
                          width: Math.max(5, node.width() * scaleX),
                          height: Math.max(5, node.height() * scaleY),
                        };
                        setRectangles(newRectangles);
                      }}
                    />
                  ))}
                </Layer>
              </Stage>
            </div>
          </div>
        </div>
        <div className="panel" ref={panelRef}>
          <div className="container">
            {rectangles.map((item, index) => {
              const isTable = item.type === "Table";

              const [key, value] = Object.entries(item).find(
                ([k]) =>
                  k !== "x" &&
                  k !== "y" &&
                  k !== "width" &&
                  k !== "height" &&
                  k !== "color"
              );
              return (
                <div
                  key={index}
                  className={`data-item ${
                    hoveredRectIndex === index ? "highlight" : ""
                  }`}
                >
                  {isTable ? (
                    <div className="table-container">
                      <DataGridComponent
                        rows={item.rows}
                        headers={item.headers}
                      />
                    </div>
                  ) : (
                    <>
                      <div className="label">{key}</div>
                      <div className="value">
                        {key === "Blob URL" ? (
                          <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View Document
                          </a>
                        ) : (
                          value
                        )}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
        </div>

        {error && <p>{error}</p>}
      </div>
    </>
  );
};


export default PreviewComponent;
