import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useLocation } from 'react-router-dom';
// import { useSelector } from 'react-redux';

const CustomBreadCrumbs = (props) => {
  const [breadCrumbData, setBreadCrumbData] = React.useState([]);
  const location = useLocation();

  React.useEffect(() => {
    if (props.array?.length > 0) {
      const updatedData = [
        {
          name: `HaawkAI - ${props.array[0].name}`,
          color: props.array[0].color,
          underline: props.array[0].underline,
          href: props.array[0].href,
        },
        ...props.array.slice(1),
      ];
      setBreadCrumbData(updatedData);
    }
  }, [props.array]);
  const ischildPage = location.pathname.includes('/preview');

  return (
    <>
      <Stack spacing={2} >
      <Breadcrumbs
          separator={
            <NavigateNextIcon fontSize='small' style={{ color: '#40408b' }} />
          }
          
          aria-label='breadcrumb'
      >
        {breadCrumbData.map((item, index) => (
          <Link
            underline={item.underline}
            color={item.color}
              key={index?.toString()}
            href={item.href}
              className='breadCrumbLink'
              style={{
                color: ischildPage && index === 0 ? "#767676" : "#101828"
              }}
          >
            {item.name}
          </Link>
        ))}
      </Breadcrumbs>
    </Stack>
    </>
  );
};

export default CustomBreadCrumbs;
