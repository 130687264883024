import React, { createContext, useContext } from "react";
import { useNavigate } from "react-router-dom";
import apiService from "../service";



// Create Context
const LogoutContext = createContext(undefined);

export const LogoutProvider = ({ children }) => {
  const navigate = useNavigate();

  const logout = async () => {
    try {
      const logoutResponse = await apiService.logoutFunc();
      if (logoutResponse.status_code === 200) {
        localStorage.clear();
        navigate("/login");
      }
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
        console.log("Logout successful");
    }
  };

  return (
    <LogoutContext.Provider value={{ logout }}>
      {children}
    </LogoutContext.Provider>
  );
};

// Custom hook to use the logout function
export const useLogout = () => {
  const context = useContext(LogoutContext);
  if (!context) {
    throw new Error("useLogout must be used within a LogoutProvider");
  }
  return context;
};
