import React from 'react';
import { IconButton, Box, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const NavigationComponent = ({ onPrevious, onNext, isPreviousDisabled, isNextDisabled, pageNumber, numPages }) => {
  return (
    <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 2,
      borderRadius: '50px',
      padding: '6px 12px',
      zIndex: 11,
      position: 'relative'
    }}
  >
    <IconButton
      onClick={onPrevious}
      disabled={numPages === 1 || isPreviousDisabled}
      sx={{
        color: 'black',
        backgroundColor: isPreviousDisabled ? '#d3d3d3' : 'white',
        borderRadius: '50%',
        transition: '0.3s',
        '&:hover': {
          backgroundColor: isPreviousDisabled ? '#d3d3d3' : '#e0e0e0',
        },
      }}
    >
      <ArrowBackIosNewIcon />
    </IconButton>
    
    <Typography sx={{ color: 'black', fontWeight: 'bold', fontSize: '1rem', color: numPages === 1 ? '#d3d3d3' : 'black' }}>
      {pageNumber} / {numPages}
    </Typography>
  
    <IconButton
      onClick={onNext}
      disabled={numPages === 1 || isNextDisabled}
      sx={{
        color: 'black',
        backgroundColor: isNextDisabled ? '#d3d3d3' : 'white',
        borderRadius: '50%',
        transition: '0.3s',
        '&:hover': {
          backgroundColor: isNextDisabled ? '#d3d3d3' : '#e0e0e0',
        },
      }}
    >
      <ArrowForwardIosIcon />
    </IconButton>
  </Box>
  );
};

export default NavigationComponent;
