import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import "./CommonDelete.css";
import ButtonComponent from "../../ButtonComponent/Button";
import deletePopupIcon from "../../../assets/deletePopup.svg";
import { CloseOutlined } from "@mui/icons-material";

const ConfirmDeleteDialog = ({ Isopen, onClose, onConfirm }) => {
  console.log("re-renderClose");
  return (
    <Dialog open={Isopen} onClose={onClose} className="common-delete-dialog">
      <div className="closeicon">
      <CloseOutlined className="close-icon" onClick={onClose} />
      </div>
      <DialogContent className="dialog-content">
        <div className="content-container">
          <img src={deletePopupIcon} alt="deletePopupIcon" className="deletePopupIcon" />
          <div className="content-text">
            <DialogTitle className="title">Delete File Permanently</DialogTitle>
            <DialogContent className="content">
            If you delete this file you wont be able to recover it. Do you want to delete it?
            </DialogContent>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="buttonAction">
        <div className="deletebutton">
          <ButtonComponent onClick={onClose} text="Cancel" className="cancel-button" />
          <ButtonComponent
            onClick={() => {
              onConfirm();
              onClose();
            }}
            text="Delete"
            className="analyse-upload"
          />
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeleteDialog;
