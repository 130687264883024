import React from "react";
import "./PasswordStrength.css"; // Import the CSS file

// Function to determine password strength
const getPasswordStrength = (password) => {
  const hasLowercase = /[a-z]/.test(password);
  const hasUppercase = /[A-Z]/.test(password);
  const hasNumbers = /\d/.test(password);
  const hasSymbols = /[!@#$%^&:*]/.test(password); // Only allowed special characters

  const strengthCount = [hasLowercase, hasUppercase, hasNumbers, hasSymbols].filter(Boolean).length;

  if (strengthCount === 1) return "Weak"; 
  if (strengthCount === 2) return "Fair"; 
  if (strengthCount === 3) return "Good"; 
  if (strengthCount === 4 && password.length < 12) return "Strong";
  if (strengthCount === 4 && password.length >= 12) return "Very Strong"; 

  return ""; // Empty for no password
};

// Function to determine the number of filled segments
const getStrengthSegments = (strength) => {
  switch (strength) {
    case "Weak":
      return [true, false, false, false, false];
    case "Fair":
      return [true, true, false, false, false];
    case "Good":
      return [true, true, true, false, false];
    case "Strong":
      return [true, true, true, true, true];
    case "Very Strong":
      return [true, true, true, true, true];
    default:
      return [false, false, false, false, false];
  }
};

// Function to determine the strength color
const getStrengthColor = (strength) => {
  switch (strength) {
    case "Weak":
      return "red";
    case "Fair":
      return "yellow";
    case "Good":
      return "yellow";
    case "Strong":
      return "#3FC014";
    case "Very Strong":
      return "#3FC014";
    default:
      return "#ddd";
  }
};
const getStrengthMessage = (strength) => {
  if (!strength) return "";
  return `Password is ${strength}`;
};

const PasswordStrengthBar = ({ password }) => {
  const passwordStrength = getPasswordStrength(password);
  const segments = getStrengthSegments(passwordStrength);
  const strengthColor = getStrengthColor(passwordStrength);
  const strengthMessage = getStrengthMessage(passwordStrength);

  return (
   <div>
     <div className="strength-bar">
      {segments.map((filled, index) => (
        <div
          key={index}
          className={`strength-segment ${filled ? "filled" : ""}`}
          style={{ backgroundColor: filled ? strengthColor : "#ddd" }}
        />
      ))}
    </div>
    <p className="strength-label" style={{ color: strengthColor }}>
        {strengthMessage}
      </p>
   </div>

  );
};

export default PasswordStrengthBar;
