// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiTypography-root.verify-test{
    font-size: 1.5rem;
    font-weight: 500;
    color: #000;
    margin-bottom: 1rem;
    text-align: center;
    margin-top: 1rem;
    font-family: "SF Pro Text";
}`, "",{"version":3,"sources":["webpack://./src/components/Verification/VerificationId.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;IACX,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;IAChB,0BAA0B;AAC9B","sourcesContent":[".MuiTypography-root.verify-test{\n    font-size: 1.5rem;\n    font-weight: 500;\n    color: #000;\n    margin-bottom: 1rem;\n    text-align: center;\n    margin-top: 1rem;\n    font-family: \"SF Pro Text\";\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
