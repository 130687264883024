import React, { useState, useRef, useEffect } from 'react';
import './Header.css';
import { useNavigate } from 'react-router-dom';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import Usageicon from "../../assets/usageIcon.png"
import Initial from '../../assets/initial.svg'; // Replace with your actual image path
import { useLogout } from '../../context/LogoutContext';
import PaymentsIcon from '@mui/icons-material/Payments';
import ButtonComponent from '../ButtonComponent/Button';
import { useCreditPoints } from '../../creditPoints';
import buyCreditButton from "../../assets/buycreditbutton.svg"

const HeaderComponent = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const { logout } = useLogout();
  const userId = localStorage.getItem('userId');
  const { creditPoints, fetchCreditPoints } = useCreditPoints();
  useEffect(() => {
    fetchCreditPoints(userId);
}, [userId, fetchCreditPoints]);

  const handleImageClick = () => {
    setDropdownOpen((prev) => !prev);
  };
  const handleClickCredit = () =>{
    navigate('/subscription')
  }
  const handleUsageClick = () =>{
    navigate('/my-usage')
  }
  const fullName = localStorage.getItem('name') || '';
  const email = localStorage.getItem('email') || '';
  const initial = fullName.charAt(0).toUpperCase();


  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleMenuClick = (action) => {
    setDropdownOpen(false);
    if(action === 'logout') {
      logout();
    }
    if(action === 'my profile') {
      navigate('/profile');
    }
    if(action === 'subscription') {
      navigate('/subscription');
    }
  };
  const userType = localStorage.getItem('userType');

  return (
    <div className="header-container">
      {/* Notification Icon */}
      {/* <div className="header-notification">
        <NotificationsOutlinedIcon className="notificationIcon" />
      </div> */}
      {/* Usage Details */}
      <div className="header-usageDetails">
        <div className="header-usageDetailsItem" >
          <ButtonComponent
            label={"My Usage"}
            icon={Usageicon}
            iconHeight={20}
            iconWidth={20}
            text="My Usage"
            onClick={handleUsageClick}
            className='usage-btn'
          />
        </div>
        </div> 
        {userType === "prepaid" && (
          <div className="header-usageDetailsItem">
          <ButtonComponent
            label={"Buy Credits"}
            icon={buyCreditButton}
            onClick={handleClickCredit}
            text={ creditPoints ? `${creditPoints} Credits` : 'Buy Credits'}
            className='analyse-upload'
          />
        </div>
        )}

      {/* Profile Initial */}
      <div className="header-initial" onClick={handleImageClick}>
        {initial}
      </div>

      {/* Profile Dropdown */}
      {dropdownOpen && (
        <div ref={dropdownRef} className="dropdown-menu">
          {/* User Details */}
          <div className="dropdown-avatarDetails">
            <div className="dropdown-name">
              {fullName} 
            </div>
            <div className="dropdown-email">{email}</div>
          </div>

          {/* Profile Menu Item */}
          <div onClick={() => handleMenuClick('my profile')} className="dropdown-itemContainer">
            <Person2OutlinedIcon className="dropdown-itemIcon" />
            <h3 className="dropdown-item">My Profile</h3>
          </div>
          {userType ===  'prepaid' && (
             <div onClick={() => handleMenuClick('subscription')} className="dropdown-itemContainer">
             <PaymentsIcon className="dropdown-itemIcon" />
             <h3 className="dropdown-item">Subscription</h3>
           </div>
          )}

          {/* Logout Menu Item */}
          <div onClick={()=>handleMenuClick('logout')} className="dropdown-itemContainer">
            <LogoutOutlinedIcon className="dropdown-logoutIcon" />
            <h3 className="dropdown-logout">Logout</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderComponent;
