import React, { useEffect, useState, useRef } from "react";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";
import "../../CriminalCertificateValidation.css";
import Tooltip from "@mui/material/Tooltip";
import { Box } from "@mui/material";
import Download from "../../assets/Download.svg";
import Plus from "../../assets/plus.svg";
import Analyse from "../../Analyse";
import Chip from "@mui/material/Chip";
import View from "../../assets/view.svg";
import Delete from "../../assets/delete.svg";
import apiService from "../../service";
import { useNavigate } from "react-router-dom";
import GridDataComponent from "../GridDataComponent/CommonTable"
import ButtonComponent from "../ButtonComponent/Button";
import './Invoice.css'

const Invoice = () => {

  const access = localStorage.getItem('access');
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    if(access.includes('invoice')) {
      setShow(true);
    }
  }, [])

  const navigate = useNavigate();
  const breadCrumbArray = [
    {
      name: "Invoice",
      color: "#767676",
      underline: "none",
      // href: "/my-ai/criminal-certification",
    },
  ];
  const [data, setData] = useState([]);
  const [dataAvailable, setDataAvailable] = useState([]);
  const [showDataGrid, setShowDataGrid] = useState(false);
  const tableHeight = `calc(100vh - 200px)`;
  const [isAnalyseOpen, setIsAnalyseOpen] = useState(false);
  const [showUploadDropdown, setShowUploadDropdown] = useState(false);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);

  const toggleUploadDropdown = () => {
    setShowUploadDropdown(!showUploadDropdown);
  };
  const addTooltipToCell = (params) => (
    <Tooltip
      title={params.value ? params.value.toString() : ""}
      arrow
      placement="left"
    >
      <span
        style={{ cursor: "pointer" }}
        className="file-name"
        onClick={() => handleFileClick(params.row.id, params.row.file_name)}
      >
        {params.value}
      </span>
    </Tooltip>
  );
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" }); // "short" for abbreviated month name
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };

  const renderDate = (params) => {
    return <div>{formatDate(params.value)}</div>;
  };
  const renderActions = (params) => {
    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          style={{ cursor: "pointer" }}
          src={View}
          onClick={() => handleFileClick(params.row.id, params.row.file_name)}
          alt="View"
          height={20}
        />
        <img
          style={{ cursor: "not-allowed" }}
          src={Delete}
          alt="Delete"
          height={20}
        />
      </div>
    );
  };

  const closeAnalysModal = () => {
    setIsAnalyseOpen(false);
    // setModalImageUrl("");
  };


  const handleFileClick = (id, file_name) => {
    navigate(`/doc-ai/invoice/preview/${id}`);
  };

  const columns = [
    {
      field: "file_name",
      headerName: "File Name",
      flex: 2,
      headerClassName: "app-theme-header",
      renderCell: (params) => addTooltipToCell(params),
    },
    {
      field: "created_at",
      headerName: "Uploaded On",
      flex: 2,
      headerClassName: "app-theme-header",
      renderCell: renderDate,
    },
    {
      field: "extracted",
      headerName: "Fields Detected",
      flex: 5,
      headerClassName: "app-theme-header",
      renderCell: (params) => renderExtractedFields(params),
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "app-theme-header",
      renderCell: renderActions,
    },
  ];

  const renderExtractedFields = (params) => {
    const extractedValuesArray = Object.values(params.row.extracted_data)
      .filter((item) => item.Content)
      .map((item) => item.Content);

    const maxChips = 3;
    const displayedFields = extractedValuesArray.slice(0, maxChips);
    const hiddenFields = extractedValuesArray.slice(maxChips);
    const hiddenFieldsCount =
      extractedValuesArray.length - displayedFields.length;
    const chipStyles = [
      { backgroundColor: "#EAECF0", color: "black" },
      { backgroundColor: "#F7E8EF", color: "#6A1039" },
      { backgroundColor: "#E8EEF7", color: "#6C86AF" },
    ];

    return (
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {displayedFields.map((field, index) => (
          <Chip
            key={field}
            label={field}
            variant="outlined"
            size="small"
            style={{
              margin: "2px",
              ...chipStyles[index],
              border: 0,
              width: "130px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          />
        ))}
        {hiddenFieldsCount > 0 && (
          <Tooltip
            title={
              <Box
                sx={{
                  display: "flex",
                  background: "white",
                  flexWrap: "wrap",
                  gap: "4px",
                  p: 1, // Padding to create some space around the chips
                }}
              >
                {hiddenFields.map((field, index) => (
                  <Chip
                    key={field}
                    label={field}
                    variant="outlined"
                    size="small"
                    style={{ margin: "2px", border: 0 }}
                  />
                ))}
              </Box>
            }
            arrow
            placement="top"
          >
            <Chip
              key="more"
              label={`+${hiddenFieldsCount}`}
              variant="outlined"
              size="small"
              style={{
                margin: "2px",
                backgroundColor: "#F2F4F7",
                color: "#344054",
              }}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiService.getInvoice();
        // console.log(response);
        setData(response);
        // setShowDataGrid(true);
        try {
          // Check if response.data.data is a valid JSON string and parse it
          const data = response?.length > 0 ? response : [];
          setDataAvailable(data);
        } catch (error) {
          console.error("Error parsing JSON data:", error);
          setDataAvailable([]); // Fallback to an empty array if parsing fails
        }
        setShowDataGrid(true);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  const openAnalyseModal = () => {
    // setModalImageUrl(imageUrl);
    setIsAnalyseOpen(true);
  };

  useEffect(() => {
    if (data != null && data.length > 0) {
      console.log(data);

      setDataAvailable(data);

      // setDataAvailable(data.length > 0); // Update dataAvailable state based on data length
    }
  }, [data]);

  return (
    <>
      {show ?   <div className="page-container">
      <CustomBreadCrumbs array={breadCrumbArray} />
      <div className="ocr-form-container App">
        {dataAvailable && showDataGrid && (
          <div className="view-all-container ">
            <div className="button-container-modal">
                <div className="button-container-end">
                  <ButtonComponent
                    onClick={() => console.log("Download Excel")}
                    className="excel-download"
                    icon={Download}
                    iconAlt="Download"
                    iconHeight={15}
                    text="Export"
                  />
                  <ButtonComponent
                    onClick={openAnalyseModal}
                    buttonRef={buttonRef}
                    className="analyse-upload"
                    icon={Plus}
                    iconAlt="Upload"
                    iconHeight={15}
                    text="Upload"
                  />
                </div>
            </div>

            {/* <MyTable records={dataAvailable} /> */}
            {showUploadDropdown && (
              <div className="upload-dropdown" ref={dropdownRef}>
                <h3>Drag and Drop to upload files</h3>
                <p>PDF files not more than 5 MB</p>
                <div className="upload-actions">
                  <button className="select-file-button">Select File</button>
                  <button
                    className="cancel-button"
                    onClick={toggleUploadDropdown}
                  >
                    Cancel
                  </button>
                  <button className="analyze-button">Analyze</button>
                </div>
              </div>
            )}
            <Box
              sx={{
                height: tableHeight,
                width: "100%",
              }}
            >
              <GridDataComponent
                rows={dataAvailable}
                columns={columns}
                className="datagrid-class"
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                disableSelectionOnClick
                disableRowSelectionOnClick
                disableColumnSelector
                disableColumnResize
                disableColumnMenu
                disableAggregation={false}
                localeText={{
                  MuiTablePagination: {
                    labelRowsPerPage: "Rows per page",
                  },
                }}
              />
            </Box>
          </div>
        )}
      </div>
      <Analyse isOpen={isAnalyseOpen} onClose={closeAnalysModal} from = {'invoice'} />
    </div> : <GeneralDocValidation />}
    </>
 
  );
};

export default Invoice;
