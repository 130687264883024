// BelgiumDocValidation.js
import React, { useEffect } from 'react';
import CriminalCertificateValidation from '../../CriminalCertificateValidation'
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";

const BelgiumDocValidation = () => {
  const access = localStorage.getItem('access')|| "";
  const [show, setShow] = React.useState(false);
  useEffect(() => {
    if(access.includes('my-ai')) {
      setShow(true);
    }else{
      setShow(false);
    }
  }, [])

  return(
    <>
      {show ? <CriminalCertificateValidation /> : <GeneralDocValidation />}
    </>
  )
  // if(access.includes('my-ai'))
  // {
  //   return (
  //     <CriminalCertificateValidation />
  //   );
  // }else {

  // }
  // return (
  //   <CriminalCertificateValidation />
  // );
};

export default BelgiumDocValidation;
