import React, { useEffect, useState, useRef } from "react";
import CustomBreadCrumbs from "../BreadCrumb/BreadCrumb";
import GeneralDocValidation from "../GeneralDocValidation/GeneralDocValidation";
import "../../CriminalCertificateValidation.css";
import { Box, Tooltip } from "@mui/material";
import Download from "../../assets/Download.svg";
import Plus from "../../assets/plus.svg";
import Analyse from "../../Analyse";
import apiService from "../../service";
import { useNavigate } from "react-router-dom";
import GridDataComponent from "../GridDataComponent/CommonTable";
import View from "../../assets/view.svg";
import Delete from "../../assets/delete.svg";
import ButtonComponent from "../ButtonComponent/Button";

const BusinessCardDetails = () => {
    const access = localStorage.getItem("access");
    const [show, setShow] = useState(false);
    const navigate = useNavigate();

    const breadCrumbArray = [
        {
            name: "Id Document",
            color: "#767676",
            underline: "none",
        },
    ];

    const [dataAvailable, setDataAvailable] = useState([]);
    const [showDataGrid, setShowDataGrid] = useState(false);
    const tableHeight = `calc(100vh - 200px)`;
    const [isAnalyseOpen, setIsAnalyseOpen] = useState(false);
    const [showUploadDropdown, setShowUploadDropdown] = useState(false);
    const buttonRef = useRef(null);
    const dropdownRef = useRef(null);

    const toggleUploadDropdown = () => {
        setShowUploadDropdown(!showUploadDropdown);
    };

    const handleFileClick = (id, file_name) => {
        navigate(`/doc-ai/id-document/preview/${id}`);
    };
    useEffect(() => {
        if (access && access.includes("gen-doc")) {
            setShow(true);
        }
    }, [access]);

    useEffect(() => {
        fetchData();
    }, []);

    //business get api calling here
    const fetchData = async () => {
        try {
            const response = await apiService.getIDDocument();
            if (response && Array.isArray(response)) {
                setDataAvailable(response);
                setShowDataGrid(true);
            } else {
                setDataAvailable([]);
                setShowDataGrid(false);
            }
        } catch (error) {
            console.error(error);
            setDataAvailable([]);
            setShowDataGrid(false);
        }
    };
    // Date format function
    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
    };

    const rows = dataAvailable.map((row, index) => {
        return {
            id: row.id,
            fileName: row.file_name,
            uploadedon: row.updated_at ? formatDate(row.updated_at) : "",
        };
    });
    const addTooltipToCell = (params) => (
        <Tooltip
          title={params.value ? params.value.toString() : ""}
          arrow
          placement="left"
        >
          <span
            style={{ cursor: "pointer" }}
            className="file-name"
            onClick={() => handleFileClick(params.row.id, params.row.file_name)}
          >
            {params.value}
          </span>
        </Tooltip>
      );

    const renderActions = (params) => {
        return (
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "8px",
                    // justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    height: "100%",
                }}
            >
                <img
                    style={{ cursor: "pointer" }}
                    src={View}
                    onClick={() => handleFileClick(params.row.id, params.row.file_name)}
                    alt="View"
                    height={20}
                />
                <img
                    style={{ cursor: "not-allowed" }}
                    src={Delete}
                    alt="Delete"
                    height={20}
                />
            </div>
        );
    };

    const columns = [
        {
            field: "id",
            headerName: "Id",
            flex: 1,
            headerClassName: "header-cell",
            headerAlign: "left",
        },
        {
            field: "fileName",
            headerName: "File Name",
            flex: 1,
            headerClassName: "header-cell",
            headerAlign: "left",
            renderCell: (params) => addTooltipToCell(params),
        },
        {
            field: "uploadedon",
            headerName: "Uploaded on",
            flex: 1,
            headerClassName: "header-cell",
            headerAlign: "left",
        },
        {
            field: "actions",
            headerName: "Actions",
            flex: 1,
            headerClassName: "header-cell",
            headerAlign: "left",
            renderCell: renderActions,
        },
    ];

    const openModalFunction = () =>{
        setIsAnalyseOpen(true);
    }

    return (
        <>
            {show ? (
                <div className="page-container">
                    <CustomBreadCrumbs array={breadCrumbArray} />
                    <div className="ocr-form-container App">
                        {dataAvailable && showDataGrid && (
                            <div className="view-all-container">
                                <div className="button-container-modal">
                                    <div className="button-container-end">
                                        <ButtonComponent
                                            onClick={() => console.log("Download Excel")}
                                            className="excel-download"
                                            icon={Download}
                                            iconAlt="Download"
                                            iconHeight={15}
                                            text="Export"
                                        />
                                        <ButtonComponent
                                            onClick={openModalFunction}
                                            buttonRef={buttonRef}
                                            className="analyse-upload"
                                            icon={Plus}
                                            iconAlt="Upload"
                                            iconHeight={15}
                                            text="Upload"
                                        />
                                    </div>
                                </div>

                                {showUploadDropdown && (
                                    <div className="upload-dropdown" ref={dropdownRef}>
                                        <h3>Drag and Drop to upload files</h3>
                                        <p>PDF files not more than 5 MB</p>
                                        <div className="upload-actions">
                                            <button className="select-file-button">
                                                Select File
                                            </button>
                                            <button
                                                className="cancel-button"
                                                onClick={toggleUploadDropdown}
                                            >
                                                Cancel
                                            </button>
                                            <button className="analyze-button">Analyze</button>
                                        </div>
                                    </div>
                                )}
                                <Box
                                    sx={{
                                        height: tableHeight,
                                        width: "100%",
                                        "& .app-theme-header": {
                                            backgroundColor: "#EAECF0",
                                            color: "black",
                                        },
                                    }}
                                >
                                    <GridDataComponent
                                        rows={rows}
                                        columns={columns}
                                        pageSize={5}
                                        className="datagrid-class"
                                        rowsPerPageOptions={[5, 10, 25]}
                                        checkboxSelection={false}
                                        localeText={{
                                            MuiTablePagination: {
                                              labelRowsPerPage: "Rows per page",
                                            },
                                          }}
                                          disableColumnMenu={false}
                                            disableColumnResize={false}
                                        disableAggregation={false}
                                    />
                                </Box>
                            </div>
                        )}
                    </div>
                    <Analyse
                        isOpen={isAnalyseOpen}
                        onClose={() => setIsAnalyseOpen(false)}
                        from={"iddocument"}
                    />
                </div>
            ) : (
                <GeneralDocValidation />
            )}
        </>
    );
};

export default BusinessCardDetails;
